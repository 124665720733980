import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-grid-table',
  templateUrl: './custom-grid-table.component.html',
  styleUrls: ['./custom-grid-table.component.scss']
})
export class CustomGridTableComponent {
  @Input() tables: any[] = [];
  @Input() isEditMode = false;
  @Output() onDataSave = new EventEmitter<any>();
  @Input() onCellRender: Function;


  saveTableData(updatedData: any, tableTitle: string) {
      this.onDataSave.emit({ tableTitle, updatedData });
  }


}
