<div class="">
  <form [formGroup]="dynamicForm">
    <div *ngFor="let key of dynamicForm.controls | keyvalue" class="dynamic-form-container">
      <ng-container *ngIf="formData[key.key].show">
        <clr-input-container class="col-12 mt-0" *ngIf="formData[key.key].type != 'client'">

          <label>{{ formData[key.key]['displayName'] | titlecase }} <span class="required"
              *ngIf="formData[key.key]['required']">*</span></label>
          <input clrInput [type]="formData[key.key].type" [formControlName]="key.key"
            [max]="key.key.includes('date') ? '2200-12-31' : null" class="w-full" />
          <clr-control-error *clrIfError="'required'">Required</clr-control-error>
          <clr-control-error *clrIfError="'maxDateInvalid'">Invalid Date (max date allowed is
            12/31/2200)</clr-control-error>
        </clr-input-container>
        <div *ngIf="formData[key.key].type === 'client'" class="mb-2">
          <label class="clr-control-label">{{ formData[key.key]['displayName'] | titlecase }} <span class="required"
              *ngIf="formData[key.key]['required']">*</span></label>
          <app-customer-list [showAddButton]="false" (onCustomerSelect)="onCustomerSelect($event, formData[key.key])">
          </app-customer-list>
        </div>
      </ng-container>
    </div>

  </form>
  <button type="submit" (click)="onSubmit()" class="btn btn-primary mt-3">Submit</button>
</div>