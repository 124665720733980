// import { Injectable } from '@angular/core';
// import { HostService } from '../host.service';
// import { TenantService } from '../tenant.service';

// declare var Keycloak: any;

// @Injectable()
// export class KeycloakService {
//   static auth: any = {};
//   static redirectUrl: string;

//   /**
//    * Initialized keycloak client
//    */
//   static init(): Promise<any> {
//     const keycloakAuth: any = new Keycloak('assets/keycloak.json');
//     KeycloakService.auth.loggedIn = false;

//     return new Promise((resolve, reject) => {
//       keycloakAuth
//         .init({ onLoad: 'login-required' })
//         .then((data) => {
//           KeycloakService.auth.loggedIn = true;
//           KeycloakService.auth.authz = keycloakAuth;
//           KeycloakService.auth.registerUrl = KeycloakService.auth.authz.createRegisterUrl();
//           // @ts-ignore
//           resolve();
//         })
//         .catch((errr) => {
//           reject();
//         });
//     });
//   }

//   /**
//    * Checks if the logged user is a member of the specified group
//    *
//    * @param groupName group name defined in keycloak
//    */
//   static hasGroup(groupName: string): boolean {
//     return (
//       KeycloakService.auth.authz != null &&
//       KeycloakService.auth.authz.authenticated &&
//       KeycloakService.auth.authz.idTokenParsed.groups.indexOf('/' + groupName) !== -1
//     );
//   }

//   /**
//    * Checks if the logged user is a member of the specified groups
//    *
//    * @param groupNames a list of group names defined in keycloak
//    */
//   static hasGroups(groupNames: string[]): boolean {
//     if (!groupNames) {
//       return false;
//     }
//     return groupNames.some((e) => {
//       if (typeof e === 'string') {
//         return KeycloakService.hasGroup(e);
//       }
//     });
//   }

//   static getUserData() {
//     return KeycloakService.auth.authz.idTokenParsed;
//   }

//   static hasRoles(roleName: string[]): boolean {
//     if (!roleName) {
//       return false;
//     }
//     return roleName.some((e) => {
//       if (typeof e === 'string') {
//         return KeycloakService.hasRole(e);
//       }
//     });
//   }

//   /**
//    * Checks if the logged user has the role specified
//    *
//    * @param roleName The name of the role
//    * @param resource The keycloak client
//    */
//   static hasRole(roleName: string, resource?: string): boolean {
//     return true 
//     // (
//       // TenantService.hasRole(roleName)
//       // KeycloakService.auth.authz.hasRealmRole(roleName) ||
//       // KeycloakService.auth.authz.hasResourceRole(roleName) ||
//       // KeycloakService.auth.authz.hasResourceRole(roleName, resource)
//     // );
//   }

//   /**
//    * Checks if the logged user has the role specified
//    *
//    */
//   static isRegisterUser(): boolean {
//     return KeycloakService.auth.authz.hasRealmRole('USER') || KeycloakService.auth.authz.hasResourceRole('USER');
//   }

//   /**
//    * Logout the current user
//    */
//   static logout() {
//     // var userInfo = HostService.getUserInfo();
//     // var userTenants = HostService.getTenants();
//     // var currentTenant = HostService.getCurrentTenant();
//     // var defaultTenant = userTenants.find(ut => ut.id == userInfo.defaultTenantId);
//     // if(defaultTenant && defaultTenant.tenancyName != currentTenant) {
//     //   HostService.setCurrentTenant(defaultTenant.tenancyName);
//     //   window.location.href = "/";
//     // } else{
//     //   KeycloakService.auth.authz.logout({
//     //     redirectUri: KeycloakService.auth.logoutUrl,
//     //   });
//     //   KeycloakService.auth.loggedIn = false;
//     //   KeycloakService.auth.authz = null;
//     // }
//   }

//   /**
//    * Redirects to keycloak login page
//    */
//   static login() {
//     KeycloakService.auth.authz.login();
//   }

//   /**
//    * Returns the token of the currently logged user
//    */
//   static getToken(): Promise<string> {
//     return new Promise<string>((resolve, reject) => {
//       if (KeycloakService.auth.authz.token) {
//         KeycloakService.auth.authz
//           .updateToken(5)
//           .success(() => {
//             resolve(KeycloakService.auth.authz.token as string);
//           })
//           .error(() => {
//             reject('Failed to refresh token');
//           });
//       }
//     });
//   }

//   /**
//    * Returns true if the current user is logged in
//    */
//   static isLogged(): boolean {
//     return KeycloakService.auth.authz != null && KeycloakService.auth.authz.authenticated;
//   }

//   /**
//    * Returns keycloak registration url
//    */
//   static createRegisterUrl() {
//     return KeycloakService.auth.registerUrl;
//   }
// }

import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import jwtDecode from "jwt-decode";
import { environment } from "src/environments/environment";

@Injectable()
export class KeycloakService {
  static auth_token: string;
  private defaultHeaders = new HttpHeaders();
  private responseType = "json";
  private withCredentials = true;
  dateTimeForCookies: Date = new Date();
  dateTimeFormatted: string;
  apiUrl: string = "";
  private openIdConfig: any = {
    token: null,
    refreshToken: null,
    realms: null,
    url: null,
    redirectUrl: null,
    clientID: null,
  };

  tokenRefreshInProgress = false;
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _httpClient: HttpClient,
    private _cookieService: CookieService
  ) {
    this.defaultHeaders = this.defaultHeaders.set("Accept", "application/json");
    this.getCookies();
    this.apiUrl = `${environment.baseUrl}/api/host`
  }

  authenticate() {
    return new Promise<boolean>(async (resolve, reject) => {
      let token = this._cookieService.get("auth_token");
      if (token) {
        resolve(true);
      }
      else {
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");
        if (code) {
          this._httpClient
            .post(
              // `${this.openIdConfig.url}/realms/${this.openIdConfig.realm}/protocol/openid-connect/token`,
              `${this.apiUrl}/authenticate`,
              { code },
              {
                withCredentials: true,
                responseType: <any>this.responseType,
              }
            )
            .subscribe({
              next: (response: any) => {
                window.location.href = window.location.origin
                resolve(true);
              },
              error: () => {
                this.clearAllCookies();
                resolve(false);
              }
            });
        } else {
          const result = await this.redirectToLogin();
          resolve(result);
        }
      }
    });
  }

  /**
   * This method will take the refresh token, and based upon the no. of revocation, it will generate and set the access-token.
   * @param refreshToken 
   * @returns set access token
   */
  refreshToken(refreshToken: string) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          `${this.apiUrl}/token/revoke`,
          { refreshToken },
          {
            withCredentials: this.withCredentials,
            responseType: <any>this.responseType,
          }
        )
        .subscribe({
          next: (response: any) => {
            return resolve(true);
          },
          error: () => {
            this.clearAllCookies();
            resolve(false);
          }
        });
    })
  }

  initialize() {
    return new Promise<boolean>((resolve, reject) => {
      this._httpClient
        .get(`${this.apiUrl}/config/keycloak`, {
          withCredentials: this.withCredentials,
          responseType: <any>this.responseType,
          headers: this.defaultHeaders,
        })
        .subscribe({
          next: (result: any) => {
            this.openIdConfig = result.success.data;
            resolve(true);
          },
          error: () => {
            reject(false);
          },
        });
    });
  }

  async redirectToLogin() {
    const isLoggedIn = await this.introspect();
    if (isLoggedIn) {
      return Promise.resolve(true);
    }
    else {
      this.clearAllCookies();
      window.location.href = `${this.openIdConfig.url}/realms/${this.openIdConfig.realm}/protocol/openid-connect/auth?response_type=code&client_id=${this.openIdConfig.clientID}`;
    }
  }

  async redirectToLogout() {
    const refreshToken = this._cookieService.get("refresh_token");
    if (refreshToken) {
      await this.logout(this._cookieService.get('refresh_token'));
    }
    await this.redirectToLogin();
  }

  async logout(refreshToken: string) {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          `${this.apiUrl}/logout`,
          { refreshToken },
          {
            withCredentials: this.withCredentials,
            responseType: <any>this.responseType,
          }
        )
        .subscribe({
          next: (response: any) => {
            this.clearAllCookies();
            return resolve(true);
          },
          error: () => {
            resolve(false);
          }
        });
    })
  }


  static getUserData() {
    const token = KeycloakService.auth_token;
    if (!token) return null;
    const decodedToken: any = jwtDecode(token);
    return decodedToken;
  }

  static hasRoles(data: any) {
    //using the roles from the session storage roles array
    let tenantUserRoles = JSON.parse(sessionStorage.getItem('user-tenant-roles'));
    if (tenantUserRoles && tenantUserRoles.roles) {
      if (data.filter((role: string) => tenantUserRoles.roles.includes(role)).length > 0) { return true; }
    }
    return false
  }

  /**
 * Checks if the logged user has the role specified
 *
 */
  static isRegisterUser(): boolean {
    // return KeycloakService.auth.authz.hasRealmRole('USER') || KeycloakService.auth.authz.hasResourceRole('USER');
    const token = KeycloakService.auth_token;
    if (!token) return false;

    const decodedToken: any = jwtDecode(token);
    const realmRoles = decodedToken?.realm_access.roles;
    if (realmRoles.includes('USER')) return true;
    return false;
  }

  getCookies() {
    KeycloakService.auth_token = this._cookieService.get("auth_token");
  }


  introspect() {
    return new Promise(async (resolve, reject) => {
      const auth_token = this._cookieService.get("auth_token");
      const refresh_token = this._cookieService.get("refresh_token");
      //need to implement a logic that can check on keycloak server as well for the session availabel or not by either making a api call by the token or by keycloak endpoint.
      if (auth_token) return resolve(true);
      else if (refresh_token) {
        const condn = await this.refreshToken(refresh_token);
        return resolve(condn);
      }
      return resolve(false);
    })
  }

  clearAllCookies() {
    this._cookieService.deleteAll("/", window.location.host, true, "None");
    this._cookieService.deleteAll("/", window.location.host, true, "Lax");
    this._cookieService.deleteAll();
  }

  setAuthTokenCookies(response: { access_token: string, refresh_token: string }) {
    this._cookieService.set('auth_token', response.access_token, 5 / (24 * 60));
    this._cookieService.set('refresh_token', response.refresh_token, 30 / (24 * 60));
  }

}

