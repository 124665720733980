<section class="bg-section-wrapper">
    <div class="">
        <div class="basic-table-layout">
            <div>
                <div class="">
                    <div class="row table-header-custom">
                        <div class="col-12 content-align-spacebt">
                            <div class="vrt-middle">
                                <h3 class="m-0 p-0">
                                    <b>Properties</b>
                                </h3>
                            </div>
                            <div class="row">
                                <div class="col-4 mt-1">
                                    <div class="toggle-margin-bottom">
                                        <input type="checkbox" clrToggle [(ngModel)]="showMap" class="btn btn-primary">
                                    </div>
                                    <span class="clr-control-label">{{showMap? "Hide Map" : "Show Map"}}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-custom-filters [filteredFields]="filterFields" [filterValues]="filterValues"
                            [saveButtonVisible]="true" (onFilterChange)="handleFilterChange($event)"
                            (saveFilterEvent)="saveFilters()" (resetAllFilters)="resetFilters()">
                        </app-custom-filters>

                        <app-custom-datatable [gridView]="false" [columns]="dtColumns" [data]="propertyData.items" [isVerticalTable]="true"
                            [totalItems]="propertyData.totalItems" [filters]="dtFilters" [sortOptions]="sortOptions"
                            [selectedSort]="selectedSort" (onSortChange)="onSortChange($event)"
                            [onCellRender]="onCellRender" (onCellClick)="cellClick($event)">
                        </app-custom-datatable>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>