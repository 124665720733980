import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent {
  @Output() emitLocation = new EventEmitter<Object>();
  @Output() emitMarkerClicked = new EventEmitter<Object>();
  inputElement: HTMLInputElement;
  @Input() searchBox: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() locationDetails: any;
  center: google.maps.LatLngLiteral;
  @Input() zoom: number = 14;
  locations: any[] = [];
  @Input() mapInputId: string = 'autocomplete';
  @ViewChild(GoogleMap) map!: GoogleMap;

  mapOptions: google.maps.MapOptions = {
    streetViewControl: false
  };

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }


  ngAfterViewInit(): void {
    if (this.isEditing) {
      if (!this.inputElement) {
        this.inputElement = document.getElementById(this.mapInputId) as HTMLInputElement;
      }
      this.initializeAutoComplete();
    }
    if (this.locationDetails) {
      if (Array.isArray(this.locationDetails)) {
        this.locations = this.locationDetails
        this.fitBounds();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locationDetails'] && this.locationDetails) {
      if (!this.locationDetails.hasOwnProperty('images')) {
        Object.assign(this.locationDetails, { images: [] })
      }
      this.updateMapCenter(this.locationDetails);
      if (Array.isArray(this.locationDetails)) {
        this.locations = this.locationDetails
        this.fitBounds();
      }
    }
  }


  updateMapCenter(details: any) {
    // Extract latitude and longitude from location details
    if (details.lat && details.lng) {
      this.center = {
        lat: details.lat,
        lng: details.lng,
      };
    }
  }


  initializeAutoComplete(): void {
    const input = this.inputElement;
    const autocomplete = new google.maps.places.Autocomplete(input, {
      fields: ['photos', 'name', 'place_id', 'formatted_address', 'geometry', 'address_components']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!this.locationDetails.hasOwnProperty('images')) {
        Object.assign(this.locationDetails, { images: [] })
      }
      if (place.geometry) {
        // Update the map center
        const location = {
          lat: place.geometry.location?.lat() || 0,
          lng: place.geometry.location?.lng() || 0,
        };

        this.center = {
          lat: location.lat,
          lng: location.lng,
        };

        // detailed location data
        Object.assign(this.locationDetails,
          {
            lat: this.center.lat?.toString(),
            lng: this.center.lng?.toString(),
            details: this.extractPlaceDetails(place),
          }
        )
      }

      if (place.photos && place.photos.length > 0) {
        // Loop through photos
        place.photos.map((photo, index) => {
          const photoUrl = photo.getUrl({ maxWidth: 400, maxHeight: 400 });
          this.locationDetails.images.push(photoUrl);
        });
      }
      else {
        this.getPlacePhotos(this.center.lat, this.center.lng);
      }
      this.cdRef.detectChanges();
      this.emitLocation.emit(this.locationDetails);
    });
  }


  extractPlaceDetails(place: google.maps.places.PlaceResult): any {
    const addressComponents = place.address_components || [];
    const details: { [key: string]: string } = {};
    details['address'] = place.formatted_address;
    // Extract specific location details
    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes('country')) {
        details['country'] = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        details['state'] = component.long_name;
      }
      if (types.includes('administrative_area_level_2')) {
        details['county'] = component.long_name;
      }
      if (types.includes('locality')) {
        details['city'] = component.long_name;
      }
      if (types.includes('sublocality') || types.includes('neighborhood')) {
        details['neighborhood'] = component.long_name;
      }
      if (types.includes('postal_code')) {
        details['zipCode'] = component.long_name;
      }
    });
    if (!details['zipCode'])
      details['zipCode'] = this.getZipCodeFromLatLng(this.center.lat, this.center.lng);
    return details;
  }

  getPlacePhotos(lat: number, lng: number) {
    const location = new google.maps.LatLng(lat, lng);
    const service = new google.maps.places.PlacesService(document.createElement('div'));

    const request = {
      location: location,
      radius: 100,
    };

    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        results.forEach((place) => {
          if (place.photos && place.photos.length > 0) {
            // Get the first photo from the place
            const photo = place.photos[0];
            const photoUrl = photo.getUrl({ maxWidth: 400, maxHeight: 400 });
            this.locationDetails.images?.push(photoUrl);            
          }
        });
      }
    });
  }

  getZipCodeFromLatLng(lat: number, lng: number): any {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        // Extract postal code
        const postalCode = results[0].address_components.find(component =>
          component.types.includes('postal_code')
        )?.long_name;

        return postalCode;
      } else {
        return null;
      }
    });
  }

  fitBounds() {
    if (!this.map || this.locations.length === 0) return;
    const bounds = new google.maps.LatLngBounds();

    // Add each marker to bounds
    this.locations.forEach(location => {
      bounds.extend(new google.maps.LatLng(location.lat, location.lng));
    });

    // Fit the map to the bounds
    this.map.googleMap?.fitBounds(bounds);
  }

  handleMapClickEvent(event, location) {
    this.emitMarkerClicked.emit(location);
  }

  createTitleContent(project) {
    return `${project.name}-(${project.projectId})`
  }
}