import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FilesService {
    private apiUrl = `${environment.baseUrl}/api/graphql`;

    constructor(private http: HttpClient) { }


    uploadFile(file): Observable<any> {
        const mutation = `
            mutation ($input: FileInput!) {
              uploadFile(input: $input) {
                id
                name
                type
                url
              }
            }
        `;

        return this.http.post<any>(this.apiUrl, {
            query: mutation,
            variables: { input: file }
        });
    }
}
