import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { validationRules } from './validation';

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
  styleUrls: ['./custom-datatable.component.scss']
})
export class CustomDatatableComponent implements OnInit {
  @Input() showHeader: boolean = false;
  @Input() showAdjustColumns: boolean = false;
  @Input() showPagination: boolean = false;
  @Input() gridView: boolean = true;
  @Input() editableGrid: boolean = false;
  @Input() showVerticleEditCol: boolean = false;
  @Input() isVerticalTable: boolean = false;
  @Input() schemaName: string = '';
  @Input() verticleTableHeaderTitle: string = '';


  @Input() columns: any[] = [];
  @Input() data: any[] = [];
  @Input() totalItems: number;
  @Input() onCellRender: Function;
  @Input() filters: any;
  @Input() sortOptions: any[] = [];
  @Input() selectedSort: { label: any; field: any, order: number };

  @Output() dataChange = new EventEmitter<any>();
  @Output() onFilterChange = new EventEmitter();
  @Output() onSortChange = new EventEmitter();
  @Output() onCellClick = new EventEmitter();

  editingRow: number | null = null;
  editingColumn: any;
  showSortingOptions: boolean = false;

  @Input() columnsPerRow: number = 1;
  @Input() numColumnsPerRow: number = 1;
  groupedColumns: any[];

  newRow: any = {};
  errors: any = {};
  isAddColumnModalOpen: boolean = false;
  newColumn: any = {};
  isDeleteModalOpen = false;
  rowToDelete: any = null;
  columnToDelete: any = null;
  columnErrors: any = {};
  isDeleteColumnModalOpen: boolean = false;
  tableViewMode: string = 'H_TABLE';
  Math = Math;
  array = Array;
  ngOnInit() {
    console.log(this.schemaName, this.isVerticalTable)
    if (this.gridView) {
      this.groupColumns();
    }
    this.storeOriginalData();
    // if(!this.data.length){
    //   this.data=[this.generateNullObject(this.columns)]
    // }
  }


  generateNullObject(columns: any[]): Record<string, any> {
    return columns.reduce((acc, column) => {
      acc[column.field] = null;
      return acc;
    }, {} as Record<string, any>);
  }

  startEdit(index: number) {
    this.editingRow = index;
  }


  saveEdit(data, id = null, index = null) {
    this.errors = (index || index === 0) ? { [index]: {} } : {};
    let isValid = true;
    let filteredColumns = this.columns.filter(column => column.visible);
    const updatedRow = this.columns.reduce((acc, col) => {
      let value = data[col.field];
      if (col.type === "number" && value !== null && value !== undefined && value !== "") {
        value = Number(value);
      }
      acc[col.field] = value;
      return acc;
    }, {} as Record<string, any>);


    filteredColumns.forEach(col => {
      this.validateField(col.field, col.type, index, updatedRow);
      const errorMessage: any = (index || index === 0) ? this.errors[index][col.field] : this.errors[col.field];
      if (errorMessage) {
        isValid = false;
      }
    });

    if (!isValid) return;
    this.editingRow = null;
    this.editingColumnIndex = null;


    if (id !== null || id === 0) {
      this.dataChange.emit({ data: updatedRow, type: 'edit', id: id, schemaName: this.schemaName });
    } else {
      const hasValidValue = Object.values(updatedRow).some(value => value !== null && value !== undefined && value !== '');
      if (hasValidValue) {
        this.dataChange.emit({ data: updatedRow, type: 'add', id: null, schemaName: this.schemaName });
      }
      else return;
    }
  }

  deleteRow(index: number) {
    this.dataChange.emit({ data: index, type: 'delete', schemaName: this.schemaName });
  }

  formatAmount(value: any) {
    value = value.toString();
    return value.replace(/[^\d.]/g, '');
  }


  renderCell(data) {
    return this.onCellRender(data);
  }


  filterChanged() {
    this.onFilterChange.emit(this.filters);
  }

  pageChanged(event) {
    this.filters.pageIndex = event.page;
    this.filterChanged();
  }

  sortChanged() {
    this.onSortChange.emit(this.selectedSort);
  }

  applySorting(option) {
    this.selectedSort = option;
    this.showSortingOptions = false;
    this.onSortChange.emit(this.selectedSort);
  }

  updateColumns() {
    // this.dataChange.emit(this.columns);
    this.dataChange.emit({ type: 'tableColumnsView', data: this.columns, schemaName: this.schemaName });

  }


  cellClick(column, item, event) {
    item.column = column;
    item.coreEvent = event;
    if (column.clickable) {
      this.onCellClick.emit(item);
    }
  }

  onCellValueChange(row: any, field: string, event: any, index: any = 0) {
    row[field] = event;
    this.editingRow = index;
  }


  /** Grid view table */

  groupColumns(): void {
    if (this.columns && this.columns.length) {
      this.groupedColumns = [];
      for (let i = 0; i < this.columns.length; i += this.columnsPerRow) {
        this.groupedColumns.push(this.columns.slice(i, i + this.columnsPerRow));
      }
    }

  }


  generateRowIndexes(totalColumns: number): number[] {
    let numRows = Math.ceil(totalColumns / this.numColumnsPerRow);
    return Array.from({ length: numRows }, (_, i) => i);
  }


  addNewRow() {
    this.errors = {};
    let isValid = true;
    // if (!this.isVerticalTable) {
    let filteredColumns = this.columns.filter(column => column.visible);
    filteredColumns.forEach(col => {
      this.validateField(col.field, col.type);
      const errorMessage: any = this.errors[col.field];
      if (errorMessage) {
        isValid = false;
      }
    });

    if (!isValid) return;
    // }

    const hasValidValue = Object.values(this.newRow).some(value => value !== null && value !== undefined && value !== '');
    if (hasValidValue) {
      this.dataChange.emit({ data: this.newRow, type: 'add', schemaName: this.schemaName });
      this.showNewInputRow = true;
      this.newRow = {};
    }
    else return;

  }

  /**
   * Validates a single field based on its type.
   * @param field - Field name in newRow
   * @param type - Type of field (text, number, date, currency, etc.)
   */
  validateField(field: string, type: string, index?: any, data?: any) {
    const column = this.columns.find(col => col.field === field);
    const value = index !== undefined ? data[field] : this.newRow[field];

    let errorMessage = null;

    if (column && column.validations) {
      errorMessage = this.applyValidations(value, column.name, column.validations);
    }
    if(!errorMessage && column.type==='number' && value<0){
      errorMessage = `${column.name} must be a positive number.`
    }

    if (index !== undefined) {
      this.errors[index] = { ...(this.errors[index] || {}), [field]: errorMessage };
    } else {
      this.errors[field] = errorMessage;
    }

  }


  applyValidations(value: any, fieldTitle: string, validations: string[]) {
    for (const rule of validations) {
      const message = validationRules[rule](value, fieldTitle);
      if (message !== true) {
        return message;
      }
    }
    return null;

  };



  openAddColumnModal() {
    this.newColumn = { name: '', field: '', type: 'text' };
    this.isAddColumnModalOpen = true;
  }

  addColumn() {
    if (!this.newColumn.name || !this.newColumn.field) {
      alert('Column Name and Field Name are required!');
      return;
    }

    // Add column dynamically
    this.columns.push({
      name: this.newColumn.name,
      field: this.newColumn.field,
      type: this.newColumn.type,
      visible: true
    });

    // Update existing data with new field
    this.data.forEach(row => {
      row[this.newColumn.field] = null;
    });

    this.editingColumn = this.newColumn
    this.isAddColumnModalOpen = false;
  }



  openDeleteModal(row: any) {
    this.rowToDelete = row;
    this.isDeleteModalOpen = true;
  }

  confirmDelete() {
    if (this.rowToDelete) {
      this.deleteRow(this.rowToDelete.id);
    }
    this.isDeleteModalOpen = false;
    this.rowToDelete = null;
  }

  editingColumnIndex: number | null = null;

  // Start Editing a Column (All Rows in that Column)
  startEditCol(colIndex: number) {
    this.editingColumnIndex = colIndex;
  }

  // Save Edited Column Data
  saveEditCol() {
    this.editingColumnIndex = null;
  }

  // Delete Column
  deleteCol(colIndex: number) {
    let fieldToDelete = this.columns[colIndex].field;

    // Remove column from data
    this.data.forEach(row => delete row[fieldToDelete]);

    // Remove column from columns array
    this.columns.splice(colIndex, 1);
  }

  // Add New Column
  addCol() {
    let newField = "field" + (this.columns.length + 1);
    this.columns.push({ name: "New Column", field: newField, type: "text" });

    this.data.forEach(row => (row[newField] = ""));
  }

  // Format Value based on column type
  formatValue(value: any, col: any): string {
    if (col.unit === "currency") {
      return value ? `$${Number(value).toFixed(2)}` : "NA";
    }
    if (col.type === "date") {
      return value ? new Date(value).toLocaleDateString("en-US") : "NA";
    }
    return value || "NA";
  }


  @Output() onDataSave = new EventEmitter<any>();
  @Input() tableTitle: any;
  @Input() isEditMode: boolean = false;
  @Output() onToggleEdit = new EventEmitter<boolean>();

  originalData: any[] = [];

  storeOriginalData() {
    if (this.data) {
      this.originalData = JSON.parse(JSON.stringify(this.data));
    }

  }

  toggleEditMode() {
    this.onToggleEdit.emit(!this.isEditMode);
  }

  saveTable() {
    this.onDataSave.emit(this.data);
  }

  cancelEdit() {
    this.data = JSON.parse(JSON.stringify(this.originalData)); // Restore original data
    this.onToggleEdit.emit(false); // Exit edit mode
  }




  editingEnabled: boolean = false;

  toggleEditing(): void {
    if (this.editingEnabled) {
      this.saveChanges();
    } else {
      this.startEditing();
    }
  }

  startEditing(): void {
    this.editingEnabled = true;
    this.originalData = JSON.parse(JSON.stringify(this.data)); // Deep copy
  }

  saveChanges(): void {
    this.editingEnabled = false;
    this.onDataSave.emit({ data: this.data, type: 'edit', schemaName: this.schemaName });
  }

  discardChanges(): void {
    this.data = JSON.parse(JSON.stringify(this.originalData));
    this.editingEnabled = false;
  }

  showNewInputRow: boolean = false

  addNewRowForEmptyData() {
    this.showNewInputRow = true;
    this.newRow = {};
  }


  updateFieldValue(index: number, field: string, value: string) {
    if (!this.data[index]) {
      this.data[index] = {}; // Ensure object exists before assigning value
    }
    this.data[index][field] = value;
  }


  updateView(view) {
    this.isVerticalTable = view;
    this.dataChange.emit({ type: 'tableView', data: view, schemaName: this.schemaName });
  }


}
