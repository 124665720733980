<div class="gallery-container">
  <h5 class="sml-extra-heading" *ngIf="!displayImages || displayImages.length === 0">No images found</h5>
  <!-- Gallery View -->
  <ng-container *ngIf="!isEditing">
    <ng-image-slider [images]="displayImages" *ngIf="displayImages.length > 0"></ng-image-slider>
  </ng-container>

  <!-- Edit View -->
  <ng-container *ngIf="isEditing">
    <div class="flex-end" *ngIf="displayImages.length>0">
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox [checked]="displayImages.length === selectedImages.length"
          (change)="selectAllImages($event)" />
        <label>{{displayImages.length !== selectedImages.length
          ?"Select All":"Deselect All"}}</label>
      </clr-checkbox-wrapper>
    </div>
    <div class="image-grid" *ngIf="displayImages.length>0">
      <div class="image-item" *ngFor="let image of displayImages">
        <div class="w-max position-relative">
          <input type="checkbox" (click)="toggleSelection(image)" class="position-top-right"
            [checked]="selectedImages.includes(image)" />
          <img [src]="image.image" alt="" width="200" height="200" />
        </div>
      </div>
    </div>

    <!-- Upload Images -->
    <div class="upload-section">
      <input type="file" class="file-input w-100" (change)="onFileSelected($event)" #fileUpload multiple />
      <button class="btn btn-primary upload-btn" (click)="fileUpload.click()">
        <i class="fa fa-upload"></i>
      </button>
    </div>
  </ng-container>
</div>