import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from './services/auth.service';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard', children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
    ],
  },

  {
    path: 'reports', canActivate: [RoleGuardService],
    data: {
      expectedRole: ['EXECUTIVE']
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/reports/reports.module').then(
            (m) => m.ReportsModule,
          ),
      },
    ],
  },
  {
    path: 'settings', canActivate: [RoleGuardService],
    data: {
      expectedRole: ['PROJECT_ADMIN'],
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/settings/settings.module').then(
            (m) => m.SettingsModule,
          ),
      },
    ],
  },

  {
    path: 'expenses',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'],
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/expense/expense.module').then(
            (m) => m.ExpenseModule,
          ),
      },
    ],
  },
  {
    path: 'projects',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'],
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./components/projects/projects.module').then((m) => m.ProjectsModule),
      },
    ],
  },
  {
    path: 'bids',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['PROJECT_ADMIN', "EMPLOYEE", 'PROJECT_MANAGER']
    }, children:
      [
        { path: '', loadChildren: () => import('./components/bidding/bidding.module').then(m => m.BiddingModule) }
      ]
  },
  {
    path: 'clients',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['PROJECT_ADMIN']
    }, children:
      [
        { path: '', loadChildren: () => import('./components/customer/customer.module').then(m => m.CustomerModule) },
      ]

  },
  {
    path: 'employees',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['EMPLOYEE', 'EMPLOYEE_ADMIN', 'EMPLOYEE_MANAGER'],
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./components/employee/employee.module').then((m) => m.EmployeeModule),
      },
    ],
  },
  {
    path: 'timesheets',
    canActivate: [RoleGuardService],
    data: {
      expectedRole: ['TIMESHEET', 'TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'],
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./components/timesheets/timesheet.module').then((m) => m.TimesheetModule),
      },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
