import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from './pipe/SafePipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Pagination } from './components/common/pagination/pagination.component';
import { DatatableComponent } from './components/common/datatable/datatable';
import { ColumnFilterComponent } from './components/common/datatable/column-filters/column-filter.component';
import { UserProfileComponent } from './components/common/user-profile/user.profile.component';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { CurrencyDirective, PercentDirective } from './components/common/directives/directives';
import { CurrencyPipe, SafePipe } from './components/common/pipes/pipes';
import { CustomerListComponent } from './components/common/customer-list/customer-list.component';
import { AppMaterialModule } from './material-module';
import { ChipsComponent } from './components/common/chips/chips.component';
import { CompanyListComponent } from './components/common/company-list/company-list.component';
import { CompanyComponent } from './components/common/company/company.component';
import { NgxOtpInputComponent } from 'ngx-otp-input';


@NgModule({
  exports: [ChipsComponent, UserProfileComponent, FormsModule, ReactiveFormsModule ,SanitizeHtmlPipe, Pagination, DatatableComponent, ColumnFilterComponent, CurrencyPipe, CurrencyDirective, PercentDirective, CustomerListComponent, CompanyListComponent, CompanyComponent, NgxOtpInputComponent],
  imports: [PaginationModule.forRoot(), FormsModule, CommonModule, ClarityModule, ReactiveFormsModule, AppMaterialModule, NgxOtpInputComponent ],
  declarations: [
    Pagination, ChipsComponent, UserProfileComponent, DatatableComponent, ColumnFilterComponent, SanitizeHtmlPipe, SafePipe, CurrencyPipe, CurrencyDirective, PercentDirective, CustomerListComponent, CompanyListComponent, CompanyComponent
  ],
  providers: [CurrencyPipe]
})
export class CommonAppModule { }
