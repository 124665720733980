<!-- Toolbar -->
<ng-container>
  <app-header class="app-header" [showHeader]="header" (activateAgent)="toggleSidebar($event)"></app-header>
</ng-container>
<div class="flex-1">
  <div class="row m-0" style="padding-top: 60px;">
    <div [ngClass]="{'col-md-9': openAgentSidebar, 'm-0': true, 'p-0': true, 'col-md-12': !openAgentSidebar}">
      <router-outlet></router-outlet>
    </div>
    <div [ngClass]="{'col-md-3': openAgentSidebar, 'm-0': true, 'p-0': true}" *ngIf="openAgentSidebar">
      <!-- bot -->
       <div class="col">        
        <!-- <div class="close-agent">close</div> -->
         <app-chat-agent></app-chat-agent>
       </div>
    </div>
  </div>
</div>
<!-- Global footer connected found files under components folder under src-->
<app-footer *ngIf="footer"></app-footer>
<clr-modal [(clrModalOpen)]="showProgressModal" [clrModalClosable]="false" [clrModalStaticBackdrop]="false">
  <div class="modal-body text-center">
    <clr-spinner>Loading ...</clr-spinner>
    <h4>Please wait ...</h4>
  </div>
</clr-modal>