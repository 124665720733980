import { Component, OnInit } from '@angular/core';
import { AlertService } from './services/alert.service';
import { NotifierService } from 'angular-notifier';
import { AuthService, RoleGuardService } from './services/auth.service';
import { EventService } from './services/event.service';
import { KeycloakService } from './services/auth/keycloak.service';
import { HostService } from './services/host.service';
import { driver, DriveStep } from "driver.js";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'canyon-web-app';
  header = false;
  showProgressModal = false;
  showProgressCounter = 0;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private notifier: NotifierService,
    private broadcaster: EventService,
    private roleGuardSerivce: RoleGuardService
  ) {
    if (this.authService.isAuthorized()) {
      this.header = true;
    }
  }

  ngOnInit() {
    this.alertService.getMessage().subscribe((message) => {
      if (message) {
        this.notifier.notify(message.type, message.text);
      }
    });

    this.broadcaster.GetEvent('LOGIN_LOGOUT').subscribe((event) => {
      this.header = this.authService.isAuthorized();
    });

    this.broadcaster.GetEvent('LOADER_EVENT').subscribe((event) => {
      if (event == "show") {
        this.showProgressCounter = this.showProgressCounter + 1;
        this.showProgressModal = true;
      } else {
        this.showProgressCounter = this.showProgressCounter - 1;
        if (this.showProgressCounter == 0) {
          this.showProgressModal = false;
        }
      }
    });

    document.getElementById('loader').style.display = "none";

    this.driverjs();
  }

  getKeycloakService() {
    return KeycloakService;
  }

  driverjs() {
    //Handling whether to repetively show guides to user or not.
    const show = localStorage.getItem('showTourGuide');
    if (show && show == 'false') return;
    localStorage.setItem('showTourGuide', "false");

    const steps: DriveStep[] = [];
    let isClosed = false, isToggled = false;

    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const isMobileView = mediaQuery.matches;
    let rolesAccess = {
      projects: ['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'],
      employees: ['EMPLOYEE', 'EMPLOYEE_ADMIN', 'EMPLOYEE_MANAGER'],
      dashboard: true,
      clients: ['PROJECT_ADMIN'],
      expense: ['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'],
      timesheets: ['TIMESHEET', 'TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'],
      reports: ['EXECUTIVE'],
      settings: ['PROJECT_ADMIN']
    }
    for (let key in rolesAccess) {
      const data = rolesAccess[key];
      if (data === true) continue;
      rolesAccess[key] = this.roleGuardSerivce.verifyRoles(data);
    }
    const driveSteps = [
      {
        popover: {
          title: "Get Started", description: "Begin with a quick tour to familiarize yourself with the main features and functionalities.",
          onNextClick: async () => {
            this.toggleNavbar();
            //required to add because having a ui issue related to time spent in opening the navbar
            setTimeout(() => {
              isToggled = true;
              driverObj.moveNext();
            }, 300);
          }
        }
      },
      rolesAccess['dashboard'] && {
        element: isMobileView ? '.dashboard-mobile' : '.dashboard',
        popover: {
          title: "Dashboard", description: "This section allows you to manage and overview all your projects. Here, you can add new projects, view existing ones, and track their progress.", side: "bottom",
          align: 'center',
        }
      },
      rolesAccess['projects'] && {
        element: isMobileView ? '.projects-mobile' : '.projects',
        popover: {
          title: "Projects", description: "This section allows you to manage and overview all your projects. You can add new projects, view existing ones, and track their progress.", side: "bottom",
          align: 'center',
          onPrevClick: () => {
            this.toggleNavbar();
            isToggled = false;
            driverObj.movePrevious();
          }
        }
      },
      rolesAccess['clients'] && {
        element: isMobileView ? '.clients-mobile' : '.clients',
        popover: {
          title: "Clients", description: "This section helps you keep track of all your clients. You can view client details, manage client information, and add new clients as needed.", side: "bottom",
          align: 'center'
        }
      },
      rolesAccess['employees'] && {
        element: isMobileView ? '.directory-mobile' : '.directory',
        popover: {
          title: "Employees", description: "In the employee section, yo will find a list of other appraisal professionals and details about their skill set, experience, and qualifications.", side: "bottom",
          align: 'center'
        }
      },
      rolesAccess['expense'] && {
        element: isMobileView ? '.expense-mobile' : '.expense',
        popover: {
          title: "Expenses", description: "This section enables you to manage and track your expenses. You can record new expenses, view past expenditures, and analyze your spending patterns.", side: "bottom",
          align: 'center'
        }
      },
      rolesAccess['timesheets'] && {
        element: isMobileView ? '.timesheets-mobile' : '.timesheets',
        popover: {
          title: "Timesheets", description: "This section is for managing timesheets. You can log work hours, review time entries, and ensure accurate tracking of employee or project time.", side: "bottom",
          align: 'center'
        }
      },
      rolesAccess['reports'] && {
        element: isMobileView ? '.reports-mobile' : '.reports',
        popover: {
          title: "Reports", description: "This section provides access to various reports. You can generate and view detailed reports on projects, expenses, and other key metrics.", side: "bottom",
          align: 'center'
        }
      },
      rolesAccess['settings'] && {
        element: isMobileView ? '.settings-mobile' : '.settings',
        popover: {
          title: "Settings", description: "This section allows you to configure application settings. You can adjust preferences, manage user accounts, and set up system parameters.", side: "bottom",
          align: 'center',
          onNextClick: () => {
            this.toggleNavbar();
            isClosed = true;
            driverObj.moveNext();
          }
        }
      },
      {
        popover: {
          title: "You're ready to go!",
          description: "Manage your projects and clients with ease. Explore our features to streamline your property valuation tasks."
        }
      }
    ]

    driveSteps.map((ds: any) => {
      if ((typeof ds) !== 'boolean') {
        steps.push(ds);
      }
    })
    const driverObj = driver({
      nextBtnText: 'Next 🡢',
      prevBtnText: '🡠 Previous',
      showProgress: true,
      showButtons: ['next', 'previous', 'close'],
      popoverClass: 'driverjs-theme',
      steps,
      onCloseClick: () => {
        !isClosed && isToggled && this.toggleNavbar();
        driverObj.destroy();
      },
      onDestroyStarted: () => {
        if (driverObj.isLastStep()) {
          !isClosed && isToggled && this.toggleNavbar();
          driverObj.destroy();
        }
        return;
      },
    })
    driverObj.drive();
  }

  toggleNavbar() {
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const isMobileView = mediaQuery.matches;
    if (navbarToggler && isMobileView) {
      navbarToggler.click();
    }
  }
}