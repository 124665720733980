<!-- Chat bot -->
<ng-container>
    <div class="chat-container">
        <div class="chat-box" #chatBox>
            <div *ngFor="let message of messages; let i = index"
                [ngClass]="{'user-message': message?.sender === 'user', 'agent-message': message?.sender === 'agent'}">
                <div [id]="i">
                    <div class="chat-header">
                        <img [src]="message?.sender === 'agent' ? './../../../assets/images/agent-icon.svg': './../../../assets/images/user_white_bg.svg'"
                            alt="img not found">
                        <div class="chat-user">
                            <span class="chat-header-text">
                                {{ message?.sender === 'agent' ? 'AI Agent' : loggedInUserData?.name }}
                            </span>
                            <span class="chat-header-text-sml">
                                {{ message?.sender === 'agent' ? 'beta' : 'Appraiser' }}
                            </span>
                        </div>
                    </div>
                    <div [ngClass]="{'message': true, 'error-message': message?.action === 'error'}"
                        [innerHTML]="message?.message" *ngIf="message?.message?.length > 0"></div>
                    <div class="component" *ngIf="action.includes(message?.action)">
                        <ng-template appDynamicHost></ng-template>
                    </div>
                    <!-- <div class="predefined-queries" *ngIf="i === 0">
                        <button *ngFor="let query of preDefinedQueries" (click)="handleSendMessage(query.message)">{{
                            query.message}}</button>
                    </div> -->
                </div>
            </div>

            <div [ngClass]="{'message-loader': true, 'agent-message': true}" *ngIf="isLoading">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            </div>

            <div [ngClass]="{'user-message': true, 'uploading-loader': true}" *ngIf="isUploading">
                <span>Uploading File...</span>
            </div>

        </div>
        <div>
            <div class="suggestion-queries">
                <button *ngFor="let suggestion of suggestions" (click)="handleSendMessage(suggestion)">{{
                    suggestion}}</button>
            </div>
        </div>
        <div class="chat-input mt-2">
            <textarea [(ngModel)]="userInput" (input)="adjustTextareaHeight($event)" (keyup.enter)="sendMessage()"
                placeholder="Type a message..." rows="1"></textarea>
            <button (click)="fileInput.click()" class="attach-button">
                <img src="./../../../../assets/images/attach.png" alt="attach">
            </button>
            <button (click)="sendMessage()" class="send-button">
                <img src="./../../../../assets/images/send.svg" alt="send">
            </button>
            <input type="file" (change)="handleFileUpload($event)" accept="application/pdf" hidden #fileInput>
        </div>
    </div>
</ng-container>