import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable()
export class AgentsService {
    private socket: WebSocketSubject<any>;
    private messageSubcription = new Subject<string>();
    messageObserver = this.messageSubcription.asObservable();
    bashUrl = '';

    constructor(
        private http: HttpClient
    ) {
        this.bashUrl = `${environment.baseUrl}/api/agents`;
    }

    healthCheck(): Observable<any> {
        return this.http.get<any>(`${this.bashUrl}/health`);
    }

    connectWebSocket(sessionId: string, callback?: () => void) {
        const employeeId = JSON.parse(sessionStorage.getItem('user-tenant-roles'))?.user?.employeeId;
        const chatUrl = window.location.hostname === 'localhost' ? environment.localChatServer : environment.chatServer;
        const wsUrl = `${chatUrl}/agent/${sessionId}?employeeId=${employeeId}`;

        this.healthCheck().subscribe({
            next: () => {
                this.socket = webSocket(wsUrl);

                this.socket.subscribe({
                    next: (message) => { },
                    error: (err) => {
                        this.socket = null;
                    },
                    complete: () => {
                        this.socket = null;
                    }
                });
                if (callback) callback();
            }
        });
    }

    disconnectWebSocket() {
        if (this.socket) {
            this.socket.complete();
            this.socket = null;
        }
    }

    sendMessage(message: any, sessionId: string) {
        if (this.socket) {
            this.socket.next(message);
        } else {
            this.connectWebSocket(sessionId, () => {
                this.socket?.next(message);
                this.updateMessageSubscriber();
            });
        }
    }

    getMessages(): Observable<any> {
        return this.socket.asObservable();
    }

    closeConnection() {
        this.socket.complete();
    }

    updateMessageSubscriber() {
        this.messageSubcription.next(null);
    }

    getSuggestions(path: string) {
        return this.http.get(`${this.bashUrl}/suggestions`, {
            params: { path: path }
        })
    }
}
