import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DirectoryService {
  baseUrl = '';

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/api/appraisers`;
  }


  searchAppraisers(params: any, limit: number, offset: number): Observable<any> {
    let queryParams = new HttpParams();
    Object.keys(params).forEach(key => {
      if (params[key]) {
        queryParams = queryParams.append(key, params[key]);
      }
    });
    queryParams = queryParams.append('limit', limit.toString());
    queryParams = queryParams.append('offset', offset.toString());

    return this.http.get<any>(`${this.baseUrl}/appraisers-license-details/searchAppraisers`, { params: queryParams });
  }
  

  getAppraiserById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/appraisers-license-details/${id}`);
  }
  

  getAppraiserDetails(limit, offset): Observable<any> {
    const params = new URLSearchParams({
      limit,
      offset
    });
  
    return this.http.get<any>(`${this.baseUrl}/appraisers-license-details?${params.toString()}`);
  }
  

}
