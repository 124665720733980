import { Injectable } from '@angular/core';
import { HostService } from './host.service';
import { TenantService } from './tenant.service';
import { environment } from 'src/environments/environment';
import { AgentsService } from './agents.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private readonly hostService: HostService, private tenantService: TenantService, private agentService: AgentsService) { }

  loadConfigurationSettings() {
    return Promise.all([
      this.loadUserDetails()
    ]).then(() => {
      return true;
    });
  }

  loadGoogleMapsScript() {
    const script = document.createElement('script');
    const key = environment.googleMapsAPIKey

    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
    script.type = 'text/javascript';
    document.head.appendChild(script);
  }

  async loadUserDetails() {
    return new Promise(async (resolve, reject) => {
      // Save default tenant if not already saved
      let currentTenant = this.hostService.getCurrentTenant();
      if (!currentTenant) {
        var userInfo = this.hostService.getUserInfo();
        var userTenants = this.hostService.getTenants();
        var defaultTenant = userTenants.find(ut => ut.id == userInfo.defaultTenantId);
        if (defaultTenant && defaultTenant.tenancyName != currentTenant.tenancyName) {
          this.hostService.setCurrentTenant(defaultTenant);
        }
      }
      await this.tenantService.loadUserRoles();
      return resolve(true);
    });
  }
}