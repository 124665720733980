import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UploadService } from '../../expense/services/upload.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
  @Input() images: any;
  @Input() googleImages: any;
  @Input() isEditing: boolean = false;
  @Output() uploadFiles = new EventEmitter<any>();
  selectedImages: any = [];
  filesToUpload: any = [];
  displayImages: any = []

  constructor(
    private awsService: UploadService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit() {
    if (this.images && this.images.length > 0)
      this.initializeExistingFilesArray(this.images);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['googleImages']) {
      this.googleImages.map((imageURL) => {
        this.displayImages.push(this.generateImagePayload(imageURL, { source: 'google' }));
      })
    }
    this.cdRef.detectChanges();
  }

  async initializeExistingFilesArray(files) {
    for (let item of files) {
      this.awsService.getImageData(item.name, 'gallery').subscribe((res) => {
        if (res?.success?.data) {
          const image = this.generateImagePayload(res.success.data, { name: item?.name, source: item?.source, mimeType: item?.mimeType });
          this.displayImages.push(image);
          this.selectedImages.push(image);
        }
      });
    }
  }


  // Handle file selection from the user's computer
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      Array.from(input.files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const imagePreview = { file: file, name: e.target.result, image: e.target.result, source: "upload" }
          this.selectedImages.push(imagePreview);
          this.displayImages.push(imagePreview);
          this.filesToUpload.push(imagePreview);
          this.upload();
        };
        reader.readAsDataURL(file);
      });
    }
  }

  // Toggle selection of an image
  toggleSelection(image: any) {
    const index = this.selectedImages.findIndex(img => img.image === image.image);
    if (index > -1) {
      this.selectedImages.splice(index, 1);
    } else {
      this.selectedImages.push(image);
    }

    const toUpload = this.images?.some((file) => file.name === image.name) || null;
    if (!toUpload) {
      const indexUpload = this.filesToUpload.findIndex(file => {
        return file.name === (image?.name || image?.image)
      });
      if (indexUpload > -1) {
        this.filesToUpload.splice(indexUpload, 1);
      }
      else {
        this.filesToUpload.push(image);
      }
    }

    this.upload();
  }

  async upload() {
    this.uploadFiles.emit({ selectedImages: this.selectedImages, filesToUpload: this.filesToUpload })
  }

  generateImagePayload(imageURL: string, options: any = null) {
    return {
      image: imageURL,
      thumbImage: imageURL,
      source: options?.source || 'upload',
      name: options?.name || imageURL,
      mimetype: options?.mimeType || null
    }
  }
  selectAllImages(event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if(isChecked){
      this.displayImages.map((image) => {
        const index = this.selectedImages.findIndex(img => img.image === image.image);
        if (index === -1) {
          this.selectedImages.push(image);
          const toUpload = this.images?.some((file) => file.name === image.name) || null;
          if (!toUpload) {
            const indexUpload = this.filesToUpload.findIndex(file => {
              return file.name === (image?.name || image?.image)
            });
            if (indexUpload > -1) {
              this.filesToUpload.splice(indexUpload, 1);
            }
            else {
              this.filesToUpload.push(image);
            }
          }
        }
      })
    }
    else{
      this.selectedImages = [];
      this.filesToUpload = [];
    }
    this.upload();
  }
}
