<div class="p15px mb-2">
  <div class="search-box" *ngIf="showSearchBar">
    <input type="text" [(ngModel)]="search" #input="ngModel" style="width: 100%" placeholder="search" id="search"
      name="search" />
  </div>
</div>
<div class="columnFilters col-md-12 px-3" *ngIf="filters.columnFilters.length > 0">
  <span *ngFor="let filter of filters.columnFilters; index as i" readonly=""
    class="label label-blue ml-2 mb-2 custom-overflow-wrap">
    {{filter.title}} {{filter.filter}} "{{filter.value || (filter.values?.length>0 ? filter.values : filter.values.value
    +' in '+
    filter.values.selected)}}"
    <a href="javascript:" (click)="removeColumnFilter(i)" class="badge badge-blue"><i class="fa fa-times"></i></a>
  </span>
</div>

<div class="row">
  <div class="col-4 pr-0" *ngIf="showMap">
    <div class="h-full pt-0">
      <app-google-maps [locationDetails]="locationCoordinates" [zoom]="6" (emitMarkerClicked)="handleMapMarkerClick($event)"></app-google-maps>
    </div>
  </div>
  <div [ngClass]="{ 'col-8': showMap, 'col-12': !showMap }">
    <div class="scroll-class">
      <div class="datatable" [ngClass]="class">
        <div class="datatable-header">
          <div *ngFor="let column of columns; index as i" class="datatable-cell" [style.min-width.px]="column.width"
            [style.text-align]="column.headerAlign">
            <a (click)="sort(column.field,column.model,column.fields, column)" style="cursor: pointer">
              {{column.name}}
              <i *ngIf="filters.sortBy == column.field && filters.sortOrder == 1" class="fa fa-arrow-up"></i>
              <i *ngIf="filters.sortBy == column.field && filters.sortOrder == 0" class="fa fa-arrow-down"></i>
            </a>
            <data-table-column-filter [data]="column" *ngIf="column.filter && i > 0"
              (onColumnFilterChange)="onColumnFilterChange($event)">
            </data-table-column-filter>
            <data-table-column-filter [data]="column" *ngIf="column.filter && i == 0" clrPosition="bottom-left"
              (onColumnFilterChange)="onColumnFilterChange($event)">
            </data-table-column-filter>
          </div>
        </div>
        <div class="datatable-body">
          <ng-container *ngIf="data.items.length>0">
            <div class="datatable-row" *ngFor="let item of data.items; index as i">
              <div class="datatable-cell" [style.min-width.px]="column.width" [style.text-align]="column.align"
                *ngFor="let column of columns; index as i" [attr.data-label]="column.name"
                (click)="cellClick(column, item, $event)" [ngStyle]="{'cursor': (column.clickable) ? 'pointer' : ''}">
                <ng-container *ngIf="column.unit == 'percentage' && column.type != 'custom' && column.type != 'date'">
                  {{item[column.field]? (item[column.field] | number : '1.2-2')+'%' : "NA"}}
                </ng-container>
                <ng-container *ngIf="column.unit == 'currency' && column.type != 'custom' && column.type != 'date'">
                  {{item[column.field]? "$" + (item[column.field] | number : '1.2-2') :'NA'}}
                </ng-container>
                <ng-container
                  *ngIf="!column.unit && column.type == 'number' && column.type != 'custom' && column.type != 'date' && column.customType!='availabilityReport'">
                  {{item[column.field]? (formatAmount(item[column.field]) | number : '1.2-2') :'NA'}}
                </ng-container>
                <!-- <ng-container
                  *ngIf="!column.unit && column.type == 'index' && column.type != 'custom' && column.type != 'date' && !column.customType"
                >
                  {{item[column.field]? (item[column.field]) :'NA'}}
                </ng-container> -->
                <ng-container
                  *ngIf="!column.unit && column.type == 'number' && column.customType == 'availabilityReport'">
                  <div [ngClass]="{
                    'shadeOfRedForValueLessThan16': item[column.field] <= 16,
                    'shadeOfRedForValueLessThan32': item[column.field] > 16 && item[column.field] <= 32,
                    'shadeOfRedForValueLessThan48': item[column.field] > 32 && item[column.field] <= 48,
                    'shadeOfRedForValueLessThan64': item[column.field] > 48 && item[column.field] <= 64,
                    'shadeOfRedForValueLessThan80': item[column.field] > 64  && item[column.field] <= 80
                  }">
                    {{item[column.field]? (item[column.field] | number : '1.2-2') :'NA'}}
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="column.type != 'currency' && column.type != 'percentage' && column.type != 'custom' && column.type != 'date' && column.type != 'number'">
                  {{item[column.field] || 'NA'}}
                </ng-container>
                <ng-container *ngIf="column.type == 'date'">
                  {{item[column.field]?(item[column.field] | date:'MM/dd/yyyy') : 'NA' }}
                </ng-container>
                <div *ngIf="column.type == 'custom'" [innerHTML]="renderCell({'field': column.field, 'data': item})">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-2 m-0 custom-pagination">
      <div class="col-sm-12 col-lg-6 full_page_counter p-0">
        <div class="dataTables_length" id="DataTables_Table_0_length">
          <label class="me-2">
            <select name="DataTables_Table_0_length" [(ngModel)]="filters.pageSize" (change)="filterChanged()"
              aria-controls="DataTables_Table_0" class="form-control">
              <option *ngFor="let pageSize of filters.pageSizes" [value]="pageSize">{{pageSize}}</option>
            </select>
          </label>
          <label>
            Showing
            <strong>{{(filters.pageIndex - 1) * filters.pageSize + 1}}</strong> -
            <strong>{{ (filters.pageSize * filters.pageIndex) > data.totalItems ? data.totalItems : filters.pageIndex *
              filters.pageSize }}</strong>
            of
            <strong>{{data.totalItems}}</strong></label>
        </div>
        <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite"></div>
      </div>
      <div class="col-sm-12 col-lg-6 main_pageing text-right p-0">
        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
          <pagination [totalItems]="data.totalItems" [maxSize]="3" [itemsPerPage]="filters.pageSize"
            [boundaryLinks]="true" (pageChanged)="pageChanged($event)" [(ngModel)]="filters.pageIndex"
            aria-label="Default pagination">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>