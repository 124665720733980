<div class="p-2 mb-2 mt-2 bg-light rounded shadow">
  <div class="row">
    <div *ngFor="let field of filteredFields; let i = index" class="col-md-4 mb-3">
      <clr-input-container class="col-12" *ngIf="field.type === 'text'">
        <label>{{ field.name }}</label>
        <input clrInput type="text" [(ngModel)]="filterValues[field.field]" placeholder="Search..." class="w-full"
          (input)="onFilterChange.emit({ field, value: filterValues[field.field] })" />
      </clr-input-container>

      <clr-input-container class="col-12" *ngIf="field.type === 'select'">
        <label>{{ field.name }}</label>
        <select clrInput [(ngModel)]="filterValues[field.field]" class="w-full"
          (change)="onFilterChange.emit({ field, value: filterValues[field.field] })">
          <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
        </select>
      </clr-input-container>

      <clr-input-container class="col-12" *ngIf="field.type === 'date'">
        <label>{{ field.name }}</label>
        <input clrInput type="date" [(ngModel)]="filterValues[field.field]" class="w-full" [min]="field.min"
          [max]="field.max" (change)="onFilterChange.emit({ field, value: filterValues[field.field] })" />
      </clr-input-container>

      <clr-select-container class="col-12" *ngIf="field.type === 'multiselect'">
        <label>{{ field.name }}</label>
        <select clrSelect multiple [(ngModel)]="filterValues[field.field]" class="w-full"
          (change)="onFilterChange.emit({ field, value: filterValues[field.field] })">
          <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
        </select>
      </clr-select-container>

      <clr-radio-container clrInline *ngIf="field.type === 'radio'">
        <label>{{ field.name }}</label>
        <clr-radio-wrapper *ngFor="let option of field.options">
          <input type="radio" clrRadio name="{{field.field}}" [(ngModel)]="filterValues[field.field]" [value]="option"
            (change)="onFilterChange.emit({ field, value: filterValues[field.field] })" />
          <label>{{ option }}</label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </div>
  </div>

  <!-- Reset Button -->
  <div class="row mt-3">
    <div class="col text-end">
      <button class="btn btn-secondary" (click)="resetAllFilters.emit()">Reset</button>
    </div>
  </div>
</div>
