<header class="header-6" *ngIf="showHeader">
  <!--Mobile header-->
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="branding">
      <!-- <a routerLink="/" class="nav-link">
        <img src="assets/images/CanyonWhiteLogo.svg" width="100%" height="25px"/>
      </a> -->
      <ul class="navbar-nav">
        <li class="nav-item"></li>
      </ul>
      <clr-dropdown>
        <button class="nav-text btn btn-default dropdown-toggle" aria-label="open user profile" clrDropdownTrigger>
          {{ selectedTenant.name }}
        </button>
        <clr-dropdown-menu clrPosition="bottom-left">
          <!--              <div aria-label="Dropdown header Action 1" clrDropdownItem><a href="javascript://">Preferences</a></div>-->
          <div *ngFor="let userTenant of userTenants" (click)="setTenant(userTenant)"
            aria-label="Dropdown header Action 2" clrDropdownItem>
            {{ userTenant.name }}
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" #mobileMenuWrapper>
      <ul class="navbar-nav">
        <!-- =================================================== -->
        <li class="nav-item">
          <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER'])">
            <clr-dropdown class="">
              <!-- trigger element -->
              <button class="nav-link nav-text drop-trigger projects-mobile" clrDropdownTrigger>Projects</button>
              <clr-dropdown-menu clrPosition="bottom-left">
                <div aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/projects">All Projects</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
                  aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/projects/add">Add Project</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
                  aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/bids">All Bids</a>
                </div>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-container>
        </li>

        <li class="nav-item">
          <ng-container>
            <clr-dropdown class="mr-0">
              <button routerLink="/dashboard" class="nav-link nav-text drop-trigger dashboard-mobile">Dashboard</button>
            </clr-dropdown>
          </ng-container>
        </li>

        
        <li class="nav-item">
          <ng-container>
            <clr-dropdown class="mr-0">
              <button routerLink="/properties" class="nav-link nav-text drop-trigger dashboard-mobile">Property</button>
            </clr-dropdown>
          </ng-container>
        </li>

        <li class="nav-item">
          <ng-container>
            <clr-dropdown class="mr-0">
              <!-- trigger element -->
              <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN'])">
                <button routerLink="/clients" class="nav-link nav-text drop-trigger clients-mobile">Clients</button>
              </ng-container>
            </clr-dropdown>
          </ng-container>
        </li>
        <li class="nav-item">
          <ng-container *ngIf="getKeycloakService().hasRoles(['EMPLOYEE', 'EMPLOYEE_ADMIN', 'EMPLOYEE_MANAGER'])">
            <clr-dropdown class="mr-0">
              <!-- trigger element -->
              <button routerLink="/employees" class="nav-link nav-text drop-trigger directory-mobile">Staffing</button>
            </clr-dropdown>
          </ng-container>
        </li>
        <li class="nav-item">
          <ng-container>
            <clr-dropdown class="mr-0">
              <!-- trigger element -->
              <button routerLink="/global-directory"
                class="nav-link nav-text drop-trigger directory-mobile">global-directory</button>
            </clr-dropdown>
          </ng-container>
        </li>
        <li class="nav-item">
          <ng-container
            *ngIf="getKeycloakService().hasRoles(['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'])">
            <clr-dropdown class="mr-2">
              <button class="nav-link nav-text drop-trigger expense-mobile" clrDropdownTrigger>Expense</button>
              <clr-dropdown-menu clrPosition="bottom-left">
                <div aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/expenses">My Expense</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
                  aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/expenses">Employee Expense</a>
                </div>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-container>
        </li>
        <ng-container
          *ngIf="getKeycloakService().hasRoles(['TIMESHEET', 'TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'])">
          <li class="nav-item">
            <clr-dropdown class="">
              <!-- trigger element -->
              <button class="nav-link nav-text drop-trigger timesheets-mobile" clrDropdownTrigger>Timesheet</button>
              <clr-dropdown-menu clrPosition="bottom-left">
                <div aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/timesheets/all">All Timesheet</a>
                </div>
                <ng-container
                  *ngIf="getKeycloakService().hasRoles(['TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'])">
                  <div aria-label="Dropdown header Action 1" clrDropdownItem>
                    <a routerLink="/timesheets/employee">Employee Timesheet</a>
                  </div>
                </ng-container>
              </clr-dropdown-menu>
            </clr-dropdown>
          </li>
        </ng-container>
        <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
          <li class="nav-item">
            <clr-dropdown class="mr-2">
              <!-- trigger element -->
              <button class="nav-link nav-text drop-trigger reports-mobile" clrDropdownTrigger>Reports</button>
              <clr-dropdown-menu clrPosition="bottom-left">
                <div aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a routerLink="/reports/accounting">Accounting</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])" aria-label="Dropdown header Action 1"
                  clrDropdownItem>
                  <a routerLink="/reports/payroll/employee">Employee Accounting</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])" aria-label="Dropdown header Action 1"
                  clrDropdownItem>
                  <a routerLink="/reports/availability/employee">Employee Availability</a>
                </div>
                <div *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])" aria-label="Dropdown header Action 1"
                  clrDropdownItem>
                  <a routerLink="/reports/payroll/company">Company Accounting</a>
                </div>
                <!-- <div *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])" aria-label="Dropdown header Action 1" clrDropdownItem>
                  <a [href]="metabaseURL">Metabase</a>
                </div> -->
              </clr-dropdown-menu>
            </clr-dropdown>
          </li>
        </ng-container>
        <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'TENANT_ADMIN'])">
          <li class="nav-item">
            <clr-dropdown class="mr-2">
              <!-- trigger element -->
              <button routerLink="/settings" class="nav-link nav-text drop-trigger settings-mobile">Settings</button>
            </clr-dropdown>
          </li>
        </ng-container>

        <ng-container>
          <button type="button" class="btn btn btn-outline" (click)="onClickAgent()">
            <img src="./../../../../assets/images/agent-icon.svg" alt="stars">
            Try AI
          </button>
        </ng-container>

        
        <!--        <li class="nav-item">
                  <clr-dropdown>
                    &lt;!&ndash; trigger element &ndash;&gt;
                    <button class="nav-link nav-text drop-trigger" clrDropdownTrigger>
                      Attendance
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left">
                      <div aria-label="Dropdown header Action 1" clrDropdownItem><a routerLink="/attendance">Punch In/Out</a></div>
                      <div aria-label="Dropdown header Action 2" clrDropdownItem><a routerLink="/attendance/view">My Attendance</a>
                      </div>
                    </clr-dropdown-menu>
                  </clr-dropdown>
                </li>-->
        <li class="nav-item">
          <clr-dropdown>
            <button class="nav-text dropdown-toggle" aria-label="open user profile" clrDropdownTrigger>
              {{ userData.name }}
            </button>
            <clr-dropdown-menu clrPosition="bottom-left">
              <!--              <div aria-label="Dropdown header Action 1" clrDropdownItem><a href="javascript://">Preferences</a></div>-->
              <div class="color-white" (click)="logout()" aria-label="Dropdown header Action 2" clrDropdownItem>
                Log out
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>
        </li>
      </ul>
    </div>
  </nav>
  <div class="header-nav">
    <!--  <a routerLink="/projects/report" class="nav-link nav-text">Project</a>-->
    <ng-container>
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button routerLink="/dashboard" class="nav-link nav-text drop-trigger dashboard">Dashboard</button>
      </clr-dropdown>
    </ng-container>

    <!-- <ng-container>
      <clr-dropdown class="mr-0">
        <button routerLink="/properties" class="nav-link nav-text drop-trigger properties">Property</button>
      </clr-dropdown>
    </ng-container> -->
    <ng-container>
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <ng-container
          *ngIf="getKeycloakService().hasRoles(['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'])">
          <button class="nav-link nav-text drop-trigger projects" clrDropdownTrigger>Projects</button>
          <clr-dropdown-menu clrPosition="bottom-left">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/projects">All Projects</a>
            </div>

            <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
              aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/projects/add">Add Project</a>
            </div>
            <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
              aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/bids">All Bids</a>
            </div>
          </clr-dropdown-menu>
        </ng-container>
      </clr-dropdown>
    </ng-container>
    <ng-container>
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN'])">
          <button routerLink="/clients" class="nav-link nav-text drop-trigger  clients">Clients</button>
        </ng-container>
      </clr-dropdown>
    </ng-container>
    <ng-container *ngIf="getKeycloakService().hasRoles(['EMPLOYEE', 'EMPLOYEE_ADMIN', 'EMPLOYEE_MANAGER'])">
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button routerLink="/employees" class="nav-link nav-text drop-trigger directory">Staffing</button>
      </clr-dropdown>
    </ng-container>
    <ng-container>
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button routerLink="/global-directory" class="nav-link nav-text drop-trigger directory-mobile">Global
          Directory</button>
      </clr-dropdown>
    </ng-container>
    <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT', 'PROJECT_ADMIN', 'PROJECT_MANAGER', 'CONTRACTOR'])">
      <clr-dropdown class="mr-0">
        <button class="nav-link nav-text drop-trigger expense" clrDropdownTrigger>Expense</button>
        <clr-dropdown-menu clrPosition="bottom-left">
          <div aria-label="Dropdown header Action 1" clrDropdownItem>
            <a routerLink="/expenses">My Expense</a>
          </div>
          <div *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'PROJECT_MANAGER'])"
            aria-label="Dropdown header Action 1" clrDropdownItem>
            <a routerLink="/expenses/employee">Employee Expense</a>
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </ng-container>
    <ng-container
      *ngIf="getKeycloakService().hasRoles(['TIMESHEET', 'TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'])">
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button class="nav-link nav-text drop-trigger timesheets" clrDropdownTrigger>Timesheet</button>
        <clr-dropdown-menu clrPosition="bottom-left">
          <div aria-label="Dropdown header Action 1" clrDropdownItem>
            <a routerLink="/timesheets/all">My Timesheet</a>
          </div>
          <ng-container
            *ngIf="getKeycloakService().hasRoles(['TIMESHEET_ADMIN', 'TIMESHEET_MANAGER', 'TIMESHEET_REVIEWER'])">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/timesheets/employee">Employee Timesheet</a>
            </div>
          </ng-container>
        </clr-dropdown-menu>
      </clr-dropdown>
    </ng-container>
    <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button class="nav-link nav-text drop-trigger reports" clrDropdownTrigger>Reports</button>
        <clr-dropdown-menu clrPosition="bottom-left">
          <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/reports/accounting">Accounting</a>
            </div>
          </ng-container>
          <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/reports/payroll/employee">Employee Accounting</a>
            </div>
          </ng-container>
          <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/reports/availability/employee">Employee Availability</a>
            </div>
          </ng-container>
          <ng-container *ngIf="getKeycloakService().hasRoles(['EXECUTIVE'])">
            <div aria-label="Dropdown header Action 1" clrDropdownItem>
              <a routerLink="/reports/payroll/company">Company Accounting</a>
            </div>
          </ng-container>
          <!-- <div aria-label="Dropdown header Action 1" clrDropdownItem>
            <a [href]="metabaseURL">Metabase</a>
          </div> -->
        </clr-dropdown-menu>
      </clr-dropdown>
    </ng-container>
    <ng-container *ngIf="getKeycloakService().hasRoles(['PROJECT_ADMIN', 'TENANT_ADMIN'])">
      <clr-dropdown class="mr-0">
        <!-- trigger element -->
        <button routerLink="/settings" class="nav-link nav-text drop-trigger settings">Settings</button>
      </clr-dropdown>
    </ng-container>
    <ng-container>
      <!-- <div class="agent-button" (click)="onClickAgent()">
        <img src="./../../../../assets/images/agent-icon.svg" alt="stars">
        Try AI
      </div> -->

      <button type="button" class="btn btn-outline agent-button" (click)="onClickAgent()">
        <img src="/assets/images/agent-icon.svg" alt="stars" class="p-1">
        Try AI
      </button>
    </ng-container>
    <!--    <clr-dropdown>
          &lt;!&ndash; trigger element &ndash;&gt;
          <button class="nav-link nav-text drop-trigger" clrDropdownTrigger>
            Attendance
          </button>
          <clr-dropdown-menu clrPosition="bottom-left">
            <div aria-label="Dropdown header Action 1" clrDropdownItem><a routerLink="/attendance">Punch In/Out</a></div>
            <div aria-label="Dropdown header Action 2" clrDropdownItem><a routerLink="/attendance/view">My Attendance</a>
            </div>
          </clr-dropdown-menu>
        </clr-dropdown>-->
  </div>
  <div class="header-actions header-actions--overflow">
    <div class="dropdown bottom-left">
      <clr-dropdown>
        <button class="nav-text dropdown-toggle" aria-label="open user profile" clrDropdownTrigger>
          {{ userData.name }}
        </button>
        <clr-dropdown-menu clrPosition="bottom-left">
          <!--          <div aria-label="Dropdown header Action 1" clrDropdownItem><a href="javascript://">Preferences</a></div>-->
          <div (click)="logout()" aria-label="Dropdown header Action 2" clrDropdownItem>Log out</div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </div>
</header>

<!-- <header class="header-6" *ngIf="!showHeader">
  <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
    <div class="branding">
      <img src="assets/images/CanyonWhiteLogo.svg" width="100%" height="25px" />
    </div>
    <div class="header-actions header-actions--overflow">
      <button class="nav-link nav-text py-0" (click)="logout()">Log In</button>
    </div>
  </nav>
</header> -->