<div class="table-header" *ngIf="showHeader || showAdjustColumns">
    <div class="table-controls">
        <div class="dropdown me-2" *ngIf="showHeader">
            <button class="btn btn-light dropdown-toggle" (click)="showSortingOptions = true" type="button"
                id="sortDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="sort-by">Sort By: {{ selectedSort?.label || 'Select' }}</span>
            </button>

            <ul class="dropdown-menu" [class.show]="showSortingOptions" aria-labelledby="sortDropdown">
                <li>
                    <h6 class="dropdown-header">Select Sorting Option</h6>
                </li>
                <li *ngFor="let option of sortOptions">
                    <h6 class="dropdown-item"
                        [class.active]="option.field === selectedSort?.field && option.order === selectedSort?.order"
                        (click)="applySorting(option)">
                        {{ option.label }}
                    </h6>
                </li>
            </ul>
        </div>


        <div class="dropdown me-2" *ngIf="showAdjustColumns">
            <button class="btn btn-light dropdown-toggle" type="button" id="colDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fas fa-sliders-h"></i> Adjust Columns
            </button>
            <ul class="dropdown-menu" aria-labelledby="colDropdown">
                <li *ngFor="let col of columns">
                    <div class="d-flex">
                        <input type="checkbox" style="margin:0px 10px;" [(ngModel)]="col.visible"
                            (change)="updateColumns()">
                        <label class="form-check-label" style="margin:0px 10px;">{{ col.name }}</label>
                    </div>

                </li>
            </ul>
        </div>
        <div class="dropdown me-2" *ngIf="showAdjustColumns">
            <button class="btn btn-light dropdown-toggle" type="button" id="colDropdown" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fas fa-grip-horizontal"></i> Adjust View
            </button>
            <ul class="dropdown-menu" aria-labelledby="colDropdown">
                <li>
                    <div class="d-flex align-items-center">
                        <input type="radio" [name]="'tableView-1-' + schemaName" [checked]="!isVerticalTable"
                            (change)="updateView(false)" style="margin: 0px 10px;cursor:pointer">
                        <label class="form-check-label">Horizontal</label>
                    </div>
                </li>
                <li>
                    <div class="d-flex align-items-center">
                        <input type="radio" [name]="'tableView-2-' + schemaName" [checked]="isVerticalTable"
                            (change)="updateView(true)" style="margin: 0px 10px;cursor:pointer">
                        <label class="form-check-label">Vertical</label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>


<!-- grid view horizontal and vertical table with no editing mode enabled-->
<div class="table-responsive" *ngIf="gridView && !editableGrid">
    <table class="table table-bordered" style="border-collapse: collapse; width: 100%; margin: 0;">
        <tbody>
            <ng-container *ngIf="!isVerticalTable; else verticalTable">
                <ng-container *ngFor="let rowIndex of generateRowIndexes(columns.length)">
                    <tr>
                        <ng-container
                            *ngFor="let col of columns.slice(rowIndex * numColumnsPerRow, (rowIndex + 1) * numColumnsPerRow)">
                            <td class="header-cell">{{ col.name }}</td>
                        </ng-container>
                    </tr>
                    <tr *ngIf="data.length">
                        <ng-container
                            *ngFor="let col of columns.slice(rowIndex * numColumnsPerRow, (rowIndex + 1) * numColumnsPerRow)">
                            <td class="data-cell">
                                <ng-container *ngIf="data[0][col.field] !== undefined; else naTemplate">
                                    <ng-container [ngSwitch]="col.type">
                                        <span *ngSwitchCase="'number'">{{ data[0][col.field] }}</span>
                                        <span *ngSwitchCase="'date'">{{ data[0][col.field] | date:'MM/dd/yyyy' }}</span>
                                        <span *ngSwitchCase="'text'">{{ data[0][col.field] }}</span>
                                        <span *ngSwitchCase="'custom'"
                                            [innerHTML]="renderCell({field: col.field, data: data[0]})"></span>
                                    </ng-container>
                                    <span *ngIf="col.unit === 'percentage'">{{ data[0][col.field] | number:'1.2-2'
                                        }}%</span>
                                    <span *ngIf="col.unit === 'currency'">${{ data[0][col.field] | number:'1.2-2'
                                        }}</span>
                                    <span *ngIf="col.unit === 'time'">${{ data[0][col.field] | number:'1.2-2'
                                        }}</span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </ng-container>

            <ng-template #verticalTable>
                <tr *ngFor="let col of columns">
                    <td class="header-cell">{{ col.name }}</td>
                    <td class="data-cell">
                        <ng-container *ngIf="data[0][col.field] !== undefined; else naTemplate">
                            <ng-container [ngSwitch]="col.type">
                                <span *ngSwitchCase="'number'">{{ data[0][col.field] }}</span>
                                <span *ngSwitchCase="'date'">{{ data[0][col.field] | date:'MM/dd/yyyy' }}</span>
                                <span *ngSwitchCase="'text'">{{ data[0][col.field] }}</span>
                            </ng-container>
                            <span *ngIf="col.unit === 'percentage'">{{ data[0][col.field] | number:'1.2-2' }}%</span>
                            <span *ngIf="col.unit === 'currency'">${{ data[0][col.field] | number:'1.2-2' }}</span>
                            <span *ngIf="col.unit === 'time'">{{ data[0][col.field] ? (data[0][col.field] |
                                number:'1.2-2')+' ms' : 'N/A' }} </span>


                        </ng-container>
                    </td>
                </tr>
            </ng-template>

            <ng-template #naTemplate>
                <span>NA</span>
            </ng-template>
        </tbody>
    </table>
</div>

<!-- ***************** Only grid view horizontal and vertical table ends ************************ -->

<!-- ****************  Editable horizontal and verticle table with add and edit row or column ********-->

<div class="table-responsive" *ngIf="!isVerticalTable && !gridView">
    <clr-datagrid>
        <clr-dg-column *ngFor="let col of columns" [hidden]="!col.visible" style="width:200px;">
            {{ col.name }}
        </clr-dg-column>
        <clr-dg-column>Actions
        </clr-dg-column>
        <ng-container *ngIf="data && data.length > 0">
            <clr-dg-row *ngFor="let row of data; let i = index">
                <clr-dg-cell *ngFor="let col of columns" [hidden]="!col.visible" (click)="cellClick(col, row, $event)"
                    [ngStyle]="{'cursor': (col.clickable) ? 'pointer' : ''}">
                    <div *ngIf="editingRow === i">
                        <ng-container [ngSwitch]="col.type">
                            <select *ngSwitchCase="'boolean'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="onCellValueChange(row, col.field, $event, i)">
                                <option [value]="true">True</option>
                                <option [value]="false">False</option>
                            </select>

                            <textarea *ngSwitchCase="'custom'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="onCellValueChange(row, col.field, $event)">
                            </textarea>

                            <select *ngSwitchCase="'select'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="onCellValueChange(row, col.field, $event, i)">
                                <option *ngFor="let option of col.options" [value]="option.value">{{ option.label }}
                                </option>
                            </select>

                            <input *ngSwitchDefault [(ngModel)]="row[col.field]" [type]="col.type || 'text'"
                                class="clr-input" (ngModelChange)="onCellValueChange(row, col.field, $event, i)" />

                        </ng-container>
                        <div *ngIf="errors?.[i] && errors?.[i][col.field]" class="text-danger">
                            {{ errors?.[i][col.field] }}
                        </div>

                    </div>

                    <ng-container *ngIf="editingRow !== i">
                        <ng-container [ngSwitch]="col.unit">
                            <ng-container *ngSwitchCase="'percentage'">
                                <span>{{ row[col.field]? (row[col.field] | number: '1.2-2') + '%' : '0%' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                <span>{{ row[col.field]? "$" + (row[col.field] | number: '1.2-2') : 0 }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <span>{{ row[col.field] ? (row[col.field] | date: 'MM/dd/yyyy') : 'NA' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'time'">
                                <span>{{ row[col.field] ? (row[col.field] | number: '1.2-2') + 'ms' : '0ms' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'distance'">
                                <span>
                                    {{ row[col.field] ? (row[col.field] | number: '1.2-2') + ' ' + row[col.unit] : 0 }}
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'custom'">
                                <div [innerHTML]="renderCell({'field': col.field, 'data': row})"></div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <span>{{ row[col.field] || 'NA' }}</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </clr-dg-cell>

                <clr-dg-cell style="outline: none !important;">
                    <div class="d-inline-flex gap-2">
                        <clr-icon shape="pencil" class="cursor-pointer" (click)="startEdit(i)"
                            *ngIf="editingRow !== i"></clr-icon>
                        <clr-icon shape="check" class="cursor-pointer text-success"
                            (click)="saveEdit(row,row.id ? row.id : i,editingRow)" *ngIf="editingRow === i"></clr-icon>
                        <clr-icon shape="trash" class="cursor-pointer text-danger ms-2"
                            (click)="openDeleteModal(row)"></clr-icon>
                    </div>
                </clr-dg-cell>
            </clr-dg-row>
        </ng-container>
        <ng-container *ngIf="data && data.length === 0 && !showNewInputRow">
            <clr-dg-row>
                <clr-dg-cell [attr.colspan]="columns?.length + 1" class="text-center">
                    No Data Found <clr-icon shape="plus-circle" class="ms-2 cursor-pointer text-primary vab"
                        (click)="addNewRowForEmptyData()"></clr-icon>
                </clr-dg-cell>
            </clr-dg-row>
        </ng-container>

        <clr-dg-row *ngIf="(data && data.length !== 0) || showNewInputRow">
            <clr-dg-cell *ngFor="let col of columns" [hidden]="!col.visible">
                <ng-container [ngSwitch]="col.type">
                    <select *ngSwitchCase="'boolean'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                        <option [value]="true">True</option>
                        <option [value]="false">False</option>
                    </select>

                    <textarea *ngSwitchCase="'custom'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                        </textarea>

                    <select *ngSwitchCase="'select'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                        <option *ngFor="let option of col.options" [value]="option.value">{{ option.label }}</option>
                    </select>

                    <input *ngSwitchDefault [(ngModel)]="newRow[col.field]" [type]="col.type || 'text'"
                        class="clr-input" (ngModelChange)="validateField(col.field, col.type)" />
                </ng-container>

                <div *ngIf="errors[col.field]" class="text-danger">{{ errors[col.field] }}</div>
            </clr-dg-cell>
            <clr-dg-cell style="outline: none !important;">
                <clr-icon shape="plus-circle" class="cursor-pointer text-primary" (click)="addNewRow()"></clr-icon>
            </clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</div>


<div *ngIf="isVerticalTable && !gridView">
    <clr-datagrid>
        <clr-dg-column></clr-dg-column>
        <clr-dg-column *ngFor="let row of data;let i=index;"><span
                *ngIf="verticleTableHeaderTitle">{{verticleTableHeaderTitle +' '+ i}}</span></clr-dg-column>
        <clr-dg-column>New</clr-dg-column>
        <clr-dg-row *ngFor="let col of columns; let colIndex = index">
            <clr-dg-cell [hidden]="!col.visible">
                {{ col.name }}
            </clr-dg-cell>
            <ng-container *ngIf="data && data.length > 0">
                <clr-dg-cell *ngFor="let row of data; let rowIndex = index" [hidden]="!col.visible"
                    style="min-width: 175px;">
                    <ng-container *ngIf="editingColumnIndex !== rowIndex">
                        <ng-container [ngSwitch]="col.unit">
                            <ng-container *ngSwitchCase="'percentage'">
                                <span>{{ row[col.field] ? (row[col.field] | number: '1.2-2') + '%' : '0%' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                <span>{{ row[col.field] ? "$" + (row[col.field] | number: '1.2-2') : 0 }}</span>
                            </ng-container>

                            <ng-container *ngSwitchCase="'time'">
                                <span>{{ row[col.field] ? (row[col.field] | number: '1.2-2') + 'ms' : '0ms' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'number'">
                                <span>{{ row[col.field] ? (row[col.field] | number: '1.2-2') : 0 }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <span>{{ row[col.field] ? (row[col.field] | date: 'MM/dd/yyyy') : 'NA' }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'distance'">
                                <span>
                                    {{ row[col.field] ? (row[col.field] | number: '1.2-2') + ' ' + row[col.unit] : 0 }}
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'custom'">
                                <div [innerHTML]="renderCell({'field': col.field, 'data': row})"></div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <span>{{ row[col.field] || 'NA' }}</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="editingColumnIndex === rowIndex">
                        <ng-container [ngSwitch]="col.type">
                            <select *ngSwitchCase="'boolean'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="validateField(col.field, col.type)">
                                <option [value]="true">True</option>
                                <option [value]="false">False</option>
                            </select>

                            <textarea *ngSwitchCase="'custom'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="validateField(col.field, col.type)">
                            </textarea>

                            <select *ngSwitchCase="'select'" [(ngModel)]="row[col.field]" class="clr-input"
                                (ngModelChange)="validateField(col.field, col.type)">
                                <option *ngFor="let option of col.options" [value]="option.value">{{ option.label }}
                                </option>
                            </select>
                            <input *ngSwitchCase="'number'" [(ngModel)]="row[col.field]" [type]="col.type || 'text'"
                            class="clr-input" min=0 (ngModelChange)="validateField(col.field, col.type)" />
                   

                            <input *ngSwitchDefault [(ngModel)]="row[col.field]" [type]="col.type || 'text'"
                                class="clr-input" (ngModelChange)="validateField(col.field, col.type)" />
                        </ng-container>
                        <div *ngIf="errors[rowIndex] && errors[rowIndex][col.field]" class="text-danger">
                            {{ errors[rowIndex][col.field] }}
                        </div>
                        <!-- <div *ngIf="errors[col.field]" class="text-danger">{{ errors[col.field] }}</div> -->
                    </ng-container>
                </clr-dg-cell>
            </ng-container>

            <clr-dg-cell [hidden]="!col.visible">
                <ng-container [ngSwitch]="col.type">
                    <select *ngSwitchCase="'boolean'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                        <option [value]="true">True</option>
                        <option [value]="false">False</option>
                    </select>

                    <textarea *ngSwitchCase="'custom'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                    </textarea>

                    <select *ngSwitchCase="'select'" [(ngModel)]="newRow[col.field]" class="clr-input"
                        (ngModelChange)="validateField(col.field, col.type)">
                        <option *ngFor="let option of col.options" [value]="option.value">{{ option.label }}</option>
                    </select>

                    <input  *ngSwitchCase="'number'"  [(ngModel)]="newRow[col.field]" [type]="col.type || 'text'"
                    class="clr-input" min="0" (ngModelChange)="validateField(col.field, col.type)" />

                    <input *ngSwitchDefault [(ngModel)]="newRow[col.field]" [type]="col.type || 'text'"
                        class="clr-input" (ngModelChange)="validateField(col.field, col.type)" />
                </ng-container>
                <div *ngIf="errors[col.field]" class="text-danger">{{ errors[col.field] }}</div>

            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-row>
            <clr-dg-cell>Actions</clr-dg-cell>
            <clr-dg-cell *ngFor="let row of data; let colIndex = index" style="outline: none !important;">
                <clr-icon shape="pencil" class="cursor-pointer" (click)="startEditCol(colIndex)"
                    *ngIf="editingColumnIndex !== colIndex"></clr-icon>
                <clr-icon shape="check" class="cursor-pointer text-success"
                    (click)="saveEdit(row,row?.id ? row?.id : colIndex,editingColumnIndex)"
                    *ngIf="editingColumnIndex === colIndex"></clr-icon>
                <clr-icon shape="trash" class="cursor-pointer text-danger ms-2"
                    (click)="openDeleteModal(row)"></clr-icon>
            </clr-dg-cell>
            <clr-dg-cell style="outline: none !important;">
                <clr-icon shape="plus-circle" class="cursor-pointer text-primary" (click)="addNewRow()"></clr-icon>

            </clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</div>


<!-- ****************  Editable horizontal and verticle table with add and edit row or column ********-->

<!-- No add row and cols starts -->

<!-- ****************  Editable verticle table with multiple editable column ********-->
<div *ngIf="isVerticalTable && gridView && editableGrid && !showVerticleEditCol">
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
        <label>{{ tableTitle }}</label>
    </div>

    <table class="table table-bordered" style="border-collapse: collapse; width: 100%; margin: 0;">
        <tbody *ngIf="columns && columns.length">
            <tr *ngFor="let i of array(Math.ceil(columns.length / columnsPerRow)).fill(0);  let rowIndex = index">
                <ng-container *ngFor="let j of [].constructor(columnsPerRow); let colIndex = index">

                    <td *ngIf="columns[rowIndex * columnsPerRow + colIndex]"
                        style="border: 1px solid black; padding: 10px; background-color: #f3e8ff99; 
                        font-weight: bold; text-align: left; min-width: 175px; white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">
                        {{ columns[rowIndex * columnsPerRow + colIndex].name }}
                    </td>

                    <td *ngIf="data && data.length > 0 && columns[rowIndex * columnsPerRow + colIndex]" style="border: 1px solid black; padding: 10px; min-width: 175px;
                        overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:255px;
                        " [attr.title]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]">

                        <ng-container *ngIf="isEditMode">
                            <!-- <input [type]="columns[rowIndex * columnsPerRow + colIndex]?.type || 'text'"
                                [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]"
                                style="width: 100%;"> -->

                            <ng-container [ngSwitch]="columns[rowIndex * columnsPerRow + colIndex]?.type">
                                <select *ngSwitchCase="'boolean'"
                                    [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]"
                                    class="clr-input"
                                    (ngModelChange)="validateField(columns[rowIndex * columnsPerRow + colIndex]?.field, columns[rowIndex * columnsPerRow + colIndex]?.type)">
                                    <option [value]="true">True</option>
                                    <option [value]="false">False</option>
                                </select>

                                <textarea *ngSwitchCase="'custom'"
                                    [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]"
                                    class="clr-input"
                                    (ngModelChange)="validateField(columns[rowIndex * columnsPerRow + colIndex]?.field, columns[rowIndex * columnsPerRow + colIndex]?.type)">
                                    </textarea>

                                <select *ngSwitchCase="'select'"
                                    [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]"
                                    class="clr-input"
                                    (ngModelChange)="validateField(columns[rowIndex * columnsPerRow + colIndex]?.field, columns[rowIndex * columnsPerRow + colIndex]?.type)">
                                    <option *ngFor="let option of columns[rowIndex * columnsPerRow + colIndex]?.options"
                                        [value]="option.value">{{ option.label }}
                                    </option>
                                </select>

                                <input *ngSwitchDefault
                                    [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]"
                                    [type]="columns[rowIndex * columnsPerRow + colIndex]?.type || 'text'"
                                    class="clr-input"
                                    (ngModelChange)="validateField(columns[rowIndex * columnsPerRow + colIndex]?.field, columns[rowIndex * columnsPerRow + colIndex]?.type)" />
                            </ng-container>
                            <!-- <div *ngIf="errors[columns[rowIndex * columnsPerRow + colIndex]?.field]" class="text-danger">{{ errors[columns[rowIndex * columnsPerRow + colIndex]?.field] }}</div> -->
                        </ng-container>

                        <ng-container *ngIf="!isEditMode && data.length">
                            <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].unit === 'percentage'">
                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ?
                                (data[0][columns[rowIndex * columnsPerRow + colIndex].field] | number:'1.2-2') + '%' :
                                'N/A' }}
                            </ng-container>
                            <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].unit === 'currency'">
                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ? '$' +
                                (data[0][columns[rowIndex * columnsPerRow + colIndex].field] | number:'1.2-2') : 'N/A'
                                }}
                            </ng-container>

                            <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].unit === 'distance'">
                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ?
                                data[0][columns[rowIndex * columnsPerRow + colIndex].field] + ' ' +
                                data[0][columns[rowIndex * columnsPerRow + colIndex].subunit] :'N/A' }}
                            </ng-container>

                            <ng-container *ngIf="!columns[rowIndex * columnsPerRow + colIndex].unit">
                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'number'">
                                    {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] || 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'date'">
                                    {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ?
                                    (data[0][columns[rowIndex * columnsPerRow + colIndex].field] | date:'MM/dd/yyyy') :
                                    'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'text'">
                                    {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] || 'N/A' }}
                                </ng-container>

                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'boolean'">
                                    {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ? 'Yes':'No' }}
                                </ng-container>


                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'custom'">
                                    <div
                                        [innerHTML]="renderCell({'field': columns[rowIndex * columnsPerRow + colIndex].field, 'data': data[0]})">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>


<!-- ****************  Editable verticle table with one editable column ********-->

<div *ngIf="isVerticalTable && gridView && editableGrid && showVerticleEditCol">
    <table class="table table-bordered" style="border-collapse: collapse; width: 100%;margin:0;">
        <tbody>
            <ng-container *ngIf="columns && columns.length">
                <tr *ngFor="let i of array(Math.ceil(columns.length / columnsPerRow)).fill(0); let rowIndex = index">
                    <ng-container *ngFor="let j of [].constructor(columnsPerRow); let colIndex = index">
                        <td *ngIf="columns[rowIndex * columnsPerRow + colIndex]"
                            style="border: 1px solid black; padding: 10px; background-color: #f3e8ff99; font-weight: bold; text-align: left; width: 250px;">
                            {{ columns[rowIndex * columnsPerRow + colIndex].name }}
                        </td>
                        <td *ngIf="data && data.length > 0 && columns[rowIndex * columnsPerRow + colIndex]"
                            style="border: 1px solid black; padding: 8px;">
                            <ng-container *ngIf="editingEnabled">
                                <input [type]="columns[rowIndex * columnsPerRow + colIndex]?.type || 'text'" class="clr-input"
                                    [(ngModel)]="data[0][columns[rowIndex * columnsPerRow + colIndex]?.field]">
                            </ng-container>
                            <ng-container *ngIf="!editingEnabled">
                                <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].unit">
                                    <ng-container [ngSwitch]="columns[rowIndex * columnsPerRow + colIndex].unit">

                                        <ng-container *ngSwitchCase="'percentage'">
                                            <span>
                                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field]
                                                ? (data[0][columns[rowIndex * columnsPerRow + colIndex].field] | number:
                                                '1.2-2') + '%'
                                                : '0%' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'currency'">
                                            <span>
                                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field]
                                                ? '$' + (data[0][columns[rowIndex * columnsPerRow + colIndex].field] |
                                                number: '1.2-2')
                                                : '$0' }}
                                            </span>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'time'">
                                            <span>
                                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field]
                                                ? (data[0][columns[rowIndex * columnsPerRow + colIndex].field] | number:
                                                '1.2-2') + ' ms'
                                                : '0 ms' }}
                                            </span>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!columns[rowIndex * columnsPerRow + colIndex].unit">
                                    <ng-container
                                        *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'number'">
                                        {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] || 'N/A' }}
                                    </ng-container>

                                    <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'date'">
                                        {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field]
                                        ? (data[0][columns[rowIndex * columnsPerRow + colIndex].field] |
                                        date:'MM/dd/yyyy')
                                        : 'N/A' }}
                                    </ng-container>

                                    <ng-container *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'text'">
                                        {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] || 'N/A' }}
                                    </ng-container>

                                    <ng-container
                                        *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'boolean'">
                                        {{ data[0][columns[rowIndex * columnsPerRow + colIndex].field] ? 'Yes' : 'No' }}
                                    </ng-container>

                                    <ng-container
                                        *ngIf="columns[rowIndex * columnsPerRow + colIndex].type === 'custom'">
                                        <div
                                            [innerHTML]="renderCell({'field': columns[rowIndex * columnsPerRow + colIndex].field, 'data': data[0]})">
                                        </div>
                                    </ng-container>

                                </ng-container>
                            </ng-container>

                            <!-- <ng-container *ngIf="!editingEnabled">
                                {{ data[0][columns[rowIndex * columnsPerRow + colIndex]?.field] || 'NA' }}
                            </ng-container> -->
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>


    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 10px;">
        <div>
            <!-- <label *ngIf="tableTitle" class="me-2">{{ tableTitle }}</label> -->
            <button (click)="toggleEditing()" class="btn btn-primary">
                {{ editingEnabled ? 'Save' : 'Edit' }}
            </button>
            <button *ngIf="editingEnabled" (click)="discardChanges()" style="margin-left: 5px;"
                class="btn btn-danger">Cancel</button>
        </div>
    </div>
</div>

<!-- ****************  Editable horizontal table with editable rows not input rows ********-->

<div class="table-responsive" *ngIf="!isVerticalTable && gridView && editableGrid">

    <table class="table table-bordered" style="border-collapse: collapse; width: 100%; margin: 0;">
        <tbody>
            <ng-container *ngFor="let rowIndex of generateRowIndexes(columns.length)">
                <tr>
                    <ng-container
                        *ngFor="let col of columns.slice(rowIndex * numColumnsPerRow, (rowIndex + 1) * numColumnsPerRow)">
                        <td
                            style="border: 1px solid black; padding: 10px; background-color: #f3e8ff99; font-weight: bold;">
                            {{ col.name }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <ng-container
                        *ngFor="let col of columns.slice(rowIndex * numColumnsPerRow, (rowIndex + 1) * numColumnsPerRow)">
                        <td style="border: 1px solid black; padding: 8px;">
                            <ng-container *ngIf="editingEnabled">
                                <ng-container [ngSwitch]="col?.type">
                                    <select *ngSwitchCase="'boolean'" [(ngModel)]="data[0][col?.field]"
                                        class="clr-input" (ngModelChange)="validateField(col?.field, col?.type)">
                                        <option [value]="true">True</option>
                                        <option [value]="false">False</option>
                                    </select>

                                    <textarea *ngSwitchCase="'custom'" [(ngModel)]="data[0][col?.field]"
                                        class="clr-input" (ngModelChange)="validateField(col?.field, col?.type)">
                                    </textarea>

                                    <select *ngSwitchCase="'select'" [(ngModel)]="data[0][col?.field]" class="clr-input"
                                        (ngModelChange)="validateField(col?.field, col?.type)">
                                        <option *ngFor="let option of col?.options" [value]="option.value">{{
                                            option.label }}
                                        </option>
                                    </select>

                                    <input *ngSwitchDefault [(ngModel)]="data[0][col?.field]"
                                        [type]="col?.type || 'text'" class="clr-input"
                                        (ngModelChange)="validateField(col?.field, col?.type)" />
                                </ng-container>
                                <!-- <input [(ngModel)]="data[0][col.field]" [type]="col.type || 'text'" class="clr-input"
                                    [ngClass]="{'is-invalid': errors[col.field]}"
                                    (blur)="validateField(col.field, col.type)" [placeholder]="'N/A'"> -->
                            </ng-container>

                            <ng-container *ngIf="!editingEnabled && data.length">
                                <ng-container *ngIf="col.unit === 'percentage'">
                                    {{ data[0][col.field] ? (data[0][col.field] | number:'1.2-2') + '%' : 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="col.unit === 'currency'">
                                    {{ data[0][col.field] ? '$' + (data[0][col.field] | number:'1.2-2') : 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="col.unit === 'time'">
                                    <span>{{ data[0][col.field] ? (data[0][col.field] | number:'1.2-2')+' ms' : 'N/A' }}
                                    </span>
                                </ng-container>

                                <ng-container *ngIf="col.unit === 'distance'">
                                    <span>{{ data[0][col.field] ? (data[0][col.field] | number:'1.2-2')+' ms' : 'N/A' }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'number'">
                                    {{ data[0][col.field] || 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="col.type === 'date'">
                                    {{ data[0][col.field] ? (data[0][col.field] | date:'MM/dd/yyyy') : 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="col.type === 'text'">
                                    {{ data[0][col.field] || 'N/A' }}
                                </ng-container>
                                <ng-container *ngIf="col.type === 'boolean'">
                                    {{ data[0][col.field] ? 'Yes':'No'}}
                                </ng-container>
                                <ng-container *ngIf="col.type === 'custom'">
                                    <div [innerHTML]="renderCell({'field': col.field, 'data': data[0]})">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>


    <div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 10px;">
        <div>
            <button (click)="toggleEditing()" class="btn btn-primary">
                {{ editingEnabled ? 'Save' : 'Edit' }}
            </button>
            <button *ngIf="editingEnabled" (click)="discardChanges()" class="btn btn-danger"
                style="margin-left: 5px;">Cancel</button>
        </div>
    </div>
</div>

<!-- No add row and cols starts -->


<!-- Add Column Modal for horizontal table -->
<clr-modal [(clrModalOpen)]="isAddColumnModalOpen">
    <h3 class="modal-title">Add New Column</h3>
    <div class="modal-body">
        <clr-input-container class="col-12">
            <label>Column Name</label>
            <input clrInput type="text" id="columnName" class="w-full" [(ngModel)]="newColumn.name"
                placeholder="Enter column name" />
            <clr-control-error *ngIf="!newColumn.name">Column name is required</clr-control-error>
        </clr-input-container>

        <clr-input-container class="col-12">
            <label>Field Name</label>
            <input clrInput type="text" id="fieldName" class="w-full" [(ngModel)]="newColumn.field"
                placeholder="Enter field name" />
            <clr-control-error *ngIf="!newColumn.field">Field name is required</clr-control-error>
        </clr-input-container>

        <clr-input-container class="col-12">
            <label>Column Type</label>
            <select clrInput [(ngModel)]="newColumn.type" class="w-full">
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="currency">Currency</option>
                <option value="percentage">Percentage</option>
            </select>
            <clr-control-error *ngIf="!newColumn.type">Please select a column type</clr-control-error>
        </clr-input-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="addColumn()">Add</button>
        <button class="btn btn-outline" (click)="isAddColumnModalOpen = false">Cancel</button>
    </div>
</clr-modal>

<!-- Delete Confirmation Modal -->
<clr-modal [(clrModalOpen)]="isDeleteModalOpen">
    <h3 class="modal-title">Confirm Delete</h3>
    <div class="modal-body">
        <p>Are you sure you want to delete this data?</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="confirmDelete()">Delete</button>
        <button class="btn btn-outline" (click)="isDeleteModalOpen = false">Cancel</button>
    </div>
</clr-modal>

<div class="row mt-2 m-0 custom-pagination" *ngIf="showPagination">
    <div class="col-sm-12 col-lg-6 full_page_counter p-0">
        <div class="dataTables_length" id="DataTables_Table_0_length">
            <label class="me-2">
                <select name="DataTables_Table_0_length" [(ngModel)]="filters.pageSize" (change)="filterChanged()"
                    aria-controls="DataTables_Table_0" class="form-control">
                    <option *ngFor="let pageSize of filters.pageSizes" [value]="pageSize">{{pageSize}}</option>
                </select>
            </label>
            <label>
                Showing
                <strong>{{(filters.pageIndex - 1) * filters.pageSize + 1}}</strong> -
                <strong>{{ (filters.pageSize * filters.pageIndex) > totalItems ? totalItems :
                    filters.pageIndex *
                    filters.pageSize }}</strong>
                of
                <strong>{{totalItems}}</strong></label>
        </div>
        <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite"></div>
    </div>
    <div class="col-sm-12 col-lg-6 main_pageing text-right p-0">
        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
            <pagination [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="filters.pageSize" [boundaryLinks]="true"
                (pageChanged)="pageChanged($event)" [(ngModel)]="filters.pageIndex" aria-label="Default pagination">
            </pagination>
        </div>
    </div>
</div>