import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatAgentComponent } from './chat-agent/chat-agent.component';
import { FormsModule } from '@angular/forms';
import { CommonAppModule } from "../../common-app-module";
import { DynamicHostDirective } from './chat-agent/dynamic-host.directive';


@NgModule({
  exports: [ChatAgentComponent],
  declarations: [
    ChatAgentComponent,
    DynamicHostDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    CommonAppModule
]
})
export class AgentsModule { }
