<div class="row h-full">
    <div class="col-12">
        <div class="google-maps">
            <google-map [height]="'100%'" [width]="'100%'" [center]="center" [zoom]="zoom" [options]="mapOptions">
                <map-marker [position]="center" *ngIf="center"></map-marker>
                <ng-container *ngFor="let location of locations">
                    <map-marker [position]="location" (mapClick)="handleMapClickEvent($event, location.project)" [title]="createTitleContent(location.project)"></map-marker>
                </ng-container>
            </google-map>
        </div>
    </div>
</div>