

export const validationRules = {
    required: (value: any, fieldTitle: string) => !!value || `${fieldTitle} is required.`,
    isDate: (value: any, fieldTitle: string) => !isNaN(Date.parse(value)) || `Invalid ${fieldTitle} format.`,
    isValidDate: (value: any, fieldTitle: string) => !isNaN(Date.parse(value)) || `Invalid ${fieldTitle} format.`,
    isNumber: (value: any, fieldTitle: string) => !isNaN(value) || `${fieldTitle} must be a number.`,
    positiveNumber: (value: any, fieldTitle: string) => value > 0 || `${fieldTitle} must be a positive number.`,
    nonNegativeNumber: (value: any, fieldTitle: string) => value >= 0 || `${fieldTitle} must be a non-negative number.`,
    validDate: (value: any) => !isNaN(Date.parse(value)) || "Invalid date format.",
    nonNegative: (value: any, fieldTitle: string) => value >= 0 || `${fieldTitle} must be a non-negative number.`,
    percentage: (value: any, fieldTitle: string) => (value >= 0 && value <= 100) || `${fieldTitle} must be between 0 and 100%.`,
    validYear: (value: any) => value >= 1800 && value <= new Date().getFullYear() || "Year Built must be a valid year.",
    isBoolean: (value: any, fieldTitle: string) => 
        value === true || value === false || value === "true" || value === "false" || `${fieldTitle} must be true or false.`,
    min: (value: any, minValue: number, fieldTitle: string) => 
        value >= minValue || `${fieldTitle} must be at least ${minValue}.`,
    max: (value: any, maxValue: number, fieldTitle: string) => 
        value <= maxValue || `${fieldTitle} must not exceed ${maxValue}.`,
    range: (value: any, minValue: number, maxValue: number, fieldTitle: string) => 
        (value >= minValue && value <= maxValue) || `${fieldTitle} must be between ${minValue} and ${maxValue}.`,
    maxLength: (value: any, maxLength: number, fieldTitle: string) => 
        (value.length <= maxLength) || `${fieldTitle} must be at most ${maxLength} characters long.`,
    minLength: (value: any, minLength: number, fieldTitle: string) => 
        (value.length >= minLength) || `${fieldTitle} must be at least ${minLength} characters long.`,
    isPhoneNumber: (value: any) => /^[0-9]{10}$/.test(value) || "Invalid phone number format (10 digits required).",
    isURL: (value: any) => /^(https?:\/\/)?([\w-]+)\.([a-z]{2,6})(\/[\w-]*)*\/?$/.test(value) || "Invalid URL format."
};
