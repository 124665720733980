<notifier-container></notifier-container>
<!-- Toolbar -->
<ng-container>
  <app-header [showHeader]="header"></app-header>
</ng-container>
<router-outlet></router-outlet>

<!-- Global footer connected found files under components folder under src-->
<app-footer></app-footer>
<clr-modal [(clrModalOpen)]="showProgressModal" [clrModalClosable]="false" [clrModalStaticBackdrop]="false">
  <div class="modal-body text-center">
    <clr-spinner>Loading ...</clr-spinner>
    <h4>Please wait ...</h4>
  </div>
</clr-modal>
