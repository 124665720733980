

export const propertyTypes = Array.from({ length: 10 }, (_, i) => {
  const propertyTypesList = [
    "Single Family",
    "Condo",
    "Townhouse",
    "Multi-Family",
    "Apartment",
    "Mobile Home",
    "Land",
    "Commercial",
    "Industrial",
    "Mixed-Use"
  ];

  return {
    property_type_id: i + 1,
    type_name: propertyTypesList[i]
  };
});


export const addresses = Array.from({ length: 10 }, (_, i) => {
  const id = 100 + i + 1;
  return {
    address_id: id,
    street_number: `${1000 + i}`,
    street_name: `Main Street ${i + 1}`,
    city: i % 2 === 0 ? "Jaipur" : "Mumbai",
    state: i % 2 === 0 ? "Rajasthan" : "Maharashtra",
    zip_code: `3020${i}`,
    latitude: 26.9124 + i * 0.001,
    longitude: 75.7873 + i * 0.001,
  };
});


export const buildingDetails = Array.from({ length: 10 }, (_, i) => {
  const id = 300 + i + 1;
  return {
    building_id: id,
    building_size: 1500 + i * 100,
    bedrooms: 2 + (i % 4),
    bathrooms: 1.5 + (i % 3) * 0.5,
    garage_spaces: i % 3 === 0 ? 2 : 1,
    garage_type: i % 3 === 0 ? "Attached" : "Detached",
    parking_spaces: 2 + (i % 3),
    parking_type: i % 2 === 0 ? "Driveway" : "Street",
    foundation_type: ["Slab", "Basement", "Crawl Space"][i % 3],
    roof_type: ["Asphalt Shingle", "Metal", "Tile"][i % 3],
    roof_material: ["Asphalt", "Metal", "Tile"][i % 3],
    exterior_finish: ["Brick", "Siding", "Stucco"][i % 3],
    construction_material: ["Wood Frame", "Concrete", "Steel"][i % 3],
    stories: 1 + (i % 3),
    year_built: 2000 + i,
    heating_type: ["Forced Air", "Radiator", "Heat Pump"][i % 3],
    cooling_type: ["Central AC", "Window Units", "None"][i % 3],
    fireplace: i % 2 === 0,
    pool: i % 4 === 0,
    central_ac: i % 2 === 0,
    basement_size: i % 3 === 0 ? 500 + i * 50 : 0,
    basement_type: i % 3 === 0 ? "Full" : "None",
    units: i % 2 === 0 ? 1 : 2,
    elevator: i % 5 === 0,
    handicap_accessible: i % 3 === 0,
    security_system: i % 2 === 0,
    energy_efficiency: ["Solar Panels", "Double Pane Windows", "None"][i % 3],
  };
});


export const propertyDummyData = Array.from({ length: 10 }, (_, i) => {
  const id = i + 1;
  return {
    property_id: id,
    address_id: 100 + id,
    address: addresses.find(addr => addr.address_id === 100 + id),
    property_type_id: id,
    property_type: propertyTypes.find(pt => pt.property_type_id === id)?.type_name,
    lot_id: 200 + id,
    building_id: 300 + id,
    building: buildingDetails.find(addr => addr.building_id === 300 + id),
    legal_description: `Lot ${id}, Block ${id % 5}, New Development Area`,
    zoning: id % 2 === 0 ? "Commercial" : "Residential",
    parcel_number: `PN-100${id}`,
    apn: `APN-200${id}`,
    tax_id: 400 + id,
    owner_id: 500 + id,
    listing_id: 600 + id,
    created_at: `2024-02-${10 + id}T09:00:00Z`,
    updated_at: `2024-02-${10 + id}T09:00:00Z`,
    subdivision: id % 2 === 0 ? "Business Park" : "Lakeview Heights",
    hoa_fees: 100 + (id * 10),
    hoa_name: id % 2 === 0 ? "Business Plaza HOA" : "Lakeview HOA",
    water_source: id % 2 === 0 ? "Municipal" : "Well",
    sewer_system: id % 2 === 0 ? "Public Sewer" : "Septic",
    utilities: "Electricity, Gas, Water, Internet",
    access: id % 2 === 0 ? "Main Road" : "Paved Road",
    view: id % 2 === 0 ? "Downtown Skyline" : "Lake",
    location_description: id % 2 === 0 ? "High-traffic area, great for business" : "Peaceful community with lake access",
    restrictions: id % 2 === 0 ? "No residential use" : "No commercial activity",
    flood_zone: id % 3 === 0 ? "Zone A" : "Zone X",
    natural_hazard_risks: id % 3 === 0 ? "Moderate flood risk" : "Low risk",
    other_features: id % 2 === 0 ? "Close to shopping mall" : "Walking trails nearby",
    notes: id % 2 === 0 ? "Good for retail stores." : "Perfect for families.",
  };
});

