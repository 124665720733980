import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-filters',
  templateUrl: './custom-filters.component.html',
  styleUrls: ['./custom-filters.component.scss']
})
export class CustomFiltersComponent {
  @Input() filteredFields: any[] = [];
  @Input() filterValues: any = {};
  @Input() saveButtonVisible: boolean = false;
  @Output() onFilterChange = new EventEmitter<{ field: any, value: any }>();
  @Output() saveFilterEvent = new EventEmitter<void>();
  @Output() resetAllFilters = new EventEmitter<void>();
}