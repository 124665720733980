import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AuthGuard, AuthInterceptor, AuthService, AuthStorage, RoleGuardService } from './services/auth.service';
import { AlertService } from './services/alert.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/layout/header/header.component';
import { ProjectService } from './services/project.service';
import { CommonService } from './services/common.service';
import { ClarityModule } from '@clr/angular';
import { EventService } from './services/event.service';
import { KeycloakService } from './services/auth/keycloak.service';
import { HostService } from './services/host.service';
import { TenantService } from './services/tenant.service';
import { NgChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { CommonAppModule } from './common-app-module';
import { StartupService } from './services/startup.service';
import { CookieService } from 'ngx-cookie-service';
import { AgentsService } from './services/agents.service';
import { AgentsModule } from './components/agents/agents.module';
import { FileDownloadService } from './services/fileDownload.service';
import { DirectoryService } from './services/directory.service';
import { FilesService } from './services/files.service';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 15,
    },
    vertical: {
      position: 'top',
      distance: 15,
      gap: 15,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

export function startupServiceFactory(startupService: StartupService, keycloakService: KeycloakService, authService: AuthService) {
  return () => {
    return new Promise((resolve, reject) => {
      if (authService.requiresAuth()) {
        keycloakService.initialize()
          .then((res) => {
            keycloakService.authenticate().then((res) => {
              startupService.loadConfigurationSettings().then((res) => {
                startupService.loadGoogleMapsScript();
                return resolve(true);
              });
            })
          })
      }
      else {
        return resolve(true);
      }
    })
  }
}
@NgModule({
  declarations: [AppComponent, FooterComponent, LoginComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    ClarityModule,
    NgChartsModule,
    CommonAppModule,
    AgentsModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    AlertService,
    ProjectService,
    DirectoryService,
    CommonService,
    EventService,
    RoleGuardService,
    KeycloakService,
    HostService,
    TenantService,
    AuthStorage,
    CookieService,
    AgentsService,
    FileDownloadService,
    FilesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService, KeycloakService, AuthService],
      multi: true
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
