import { ChangeDetectorRef, Component, ComponentRef, ElementRef, Injector, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { GoogleMapsComponent } from '../../common/google-maps/google-maps.component';
import { AgentsService } from 'src/app/services/agents.service';
import { DynamicHostDirective } from './dynamic-host.directive';
import { KeycloakService } from 'src/app/services/auth/keycloak.service';
import { GalleryComponent } from '../../common/gallery/gallery.component';
import { HostService } from 'src/app/services/host.service';
import { DynamicFormComponent } from '../../common/dynamic-form/dynamic-form.component';
import { v4 as uuidv4 } from 'uuid';
import { ReportWritingService } from '../../projects/components/project/report-writing/report-writing.service';
import { UploadService } from '../../expense/services/upload.service';
import { lastValueFrom } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FilesService } from 'src/app/services/files.service';
import { environment } from 'src/environments/environment';

interface message {
  message?: string;
  sender: 'user' | 'agent',
  action?: 'query' | 'maps' | 'create',
  data?: any,
  workflowName?: string
}

@Component({
  selector: 'app-chat-agent',
  templateUrl: './chat-agent.component.html',
  styleUrls: ['./chat-agent.component.scss']
})
export class ChatAgentComponent {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false }) container: ViewContainerRef | undefined;
  @ViewChild('chatBox') private chatBox!: ElementRef;
  @ViewChildren(DynamicHostDirective) dynamicHosts!: QueryList<DynamicHostDirective>;

  projectMessages: message[] = []
  messages: message[] = [{
    message: "Hey, what do you want me to do?",
    sender: "agent",
    action: 'query'
  }]
  userInput: string = '';
  mapCenter: any = {};
  projectsPage: boolean = false;
  loggedInUserData: any;
  isLoading: boolean = false
  mapCount: number = 1;
  suggestions: string[] = ['Hi there', "Appraisal", "Report Writing"];
  sessionId: string = null;

  selectedProperty: {
    lat: any, lng: any, details: any, images: any
  };

  action = ['maps', 'form', 'Form']

  componentMap = {
    "GoogleMapsComponent": GoogleMapsComponent,
    "GalleryComponent": GalleryComponent,
    "DynamicFormComponent": DynamicFormComponent
  }

  userInfo: any
  preDefinedQueries = [];
  sanitizedFileURL: SafeResourceUrl | null = null;
  isUploading: boolean = false;
  files = [];
  chatData = {}
  currentPath = ''

  constructor(
    private injector: Injector,
    private agentsService: AgentsService,
    private cdRef: ChangeDetectorRef,
    private hostService: HostService,
    private reportWritingService: ReportWritingService,
    private uploadService: UploadService,
    private filesService: FilesService,
  ) {
  }

  ngOnInit() {
    this.loggedInUserData = KeycloakService.getUserData();
    this.userInfo = this.hostService.getUserInfo();
    this.createSocketConnection();

    this.agentsService.messageObserver.subscribe((res) => {
      this.listenForMessages();
    })
  }


  ngDoCheck() {
    const url = window.location.pathname;
    if (this.currentPath != url) {
      this.currentPath = url;
      this.handleSuggestions();
    }
  }

  handleSuggestions(suggestions = [], skipCall = false) {
    skipCall ?
      this.suggestions = suggestions
      :
      this.agentsService.getSuggestions(this.currentPath).subscribe((res: any) => {
        suggestions = res
        this.suggestions = suggestions
      })
  }

  createSocketConnection() {
    this.sessionId = uuidv4()
    this.agentsService.connectWebSocket(this.sessionId, () => {
      this.listenForMessages()
    })
  }

  async handleFileUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) return;

    try {
      this.isUploading = true;
      // Get AWS Signed URL
      const res = await lastValueFrom(this.uploadService.getUploadUrl(1, "chat-uploads"));

      if (!res || !res.success || !res.success.data || res.success.data.length === 0) {
        throw new Error("Failed to get upload URL");
      }

      const fileUploadUrl = res.success.data[0]?.urls[0];
      if (!fileUploadUrl) throw new Error("Invalid upload URL");


      // Upload file to AWS
      await lastValueFrom(this.uploadService.uploadFile(input.files[0], fileUploadUrl));

      // Store the uploaded file URL and sanitize it for safe preview
      const fileURL = fileUploadUrl.split('?')[0];

      const file = {
        url: fileURL,
        type: input.files[0].type,
        name: input.files[0].name
      }

      // save file into the database
      this.filesService.uploadFile(file).subscribe((res) => {
        this.isUploading = false;
        const uploadedFile = res.data.uploadFile;
        let tenant = this.hostService.getCurrentTenant();

        // creating the file url to fetch from files stored
        file.url = `${environment.baseUrl}/api/host/files/${uploadedFile.id}/${tenant.tenancyName}/${input.files[0].name}`
        this.files.push(file)

        // Sending an message from user side
        this.handleSendMessage(`${input.files[0].name}`);
      })

    } catch (error) {
      // console.log("File upload failed:", error);
    }
  }

  sanitizeFilename(projectName, maxLength = 50) {
    let cleanName = projectName.replace(/[^\w\s-]/g, "");
    cleanName = cleanName.replace(/\s+/g, "_");
    if (cleanName.length > maxLength) {
      cleanName = cleanName.substring(0, maxLength);
    }
    return cleanName;
  }


  scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatBox) {
        try {
          this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
        } catch (err) {
          console.error("Scroll error:", err);
        }
      }
    }, 100);
  }

  listenForMessages() {
    this.agentsService.getMessages().subscribe((res) => {
      this.isLoading = false;

      const { message, data, action, workflowName, suggestions } = res;
      let messageError = "Something Went Wrong"

      this.messages.push({ sender: 'agent', message: message || messageError, action: action, workflowName: workflowName });
      this.scrollToBottom();
      this.handleSuggestions(suggestions, true);
      // Handle actions (load components dynamically)
      if (action?.toLowerCase() === 'maps') {
        this.cdRef.detectChanges();
        this.loadComponent('GoogleMapsComponent', data);
        // this.loadComponent('GalleryComponent');
      }
      else if (action?.toLowerCase() === 'form') {
        this.cdRef.detectChanges();
        this.loadComponent("DynamicFormComponent", data);
      }
      else if (action?.toLowerCase() === 'reportwriting') {
        this.reportWritingService.updateReport(data)
      }

    });
  }

  adjustTextareaHeight(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "40px"; // Reset height
    textarea.style.height = Math.min(textarea.scrollHeight, 120) + "px"; // Auto-expand but limit height
  }

  sendMessage() {
    if (this.userInput.trim() && !this.isLoading) {
      const lastMessage = this.messages.at(-1) || null;
      this.messages.push({ message: this.userInput, sender: 'user', action: 'query' });
      this.scrollToBottom();
      this.sendMessageToAgent({ message: this.userInput, workflowName: lastMessage?.workflowName || "None" });
    }
  }

  sendMessageToAgent(payload: any): void {
    if (!this.isLoading && !this.isUploading) {
      this.isLoading = true;
      const data = {
        workflowName: payload.workflowName,
        data: payload?.formData || {},
        url: window.location.pathname,
        files: this.files,
        templateId: localStorage.getItem('opened-project-templateId') || null
      }
      this.agentsService.sendMessage(
        {
          message: payload?.message || null,
          data
        }, this.sessionId
      );
      this.userInput = '';
      this.files = [];
      this.scrollToBottom();
      this.cdRef.detectChanges();
    }
  }


  handleSendMessage(message: string) {
    this.userInput = message;
    this.sendMessage();
  }

  loadComponent(componentName: string, data = null) {
    const componentType = this.componentMap[componentName];
    if (componentType) {
      // Get all dynamic host containers as an array
      const hostsArray = this.dynamicHosts.toArray();

      if (hostsArray.length > 0) {
        // Assume we want to load the component into the most-recent container
        const latestHost = hostsArray[hostsArray.length - 1];
        // latestHost.viewContainerRef.clear();
        const componentRef: ComponentRef<any> = latestHost.viewContainerRef.createComponent(componentType, { injector: this.injector });
        const instance = componentRef.instance;

        const googleSearchId = 'googleSearch' + this.mapCount++;
        if (componentName === 'GoogleMapsComponent') {
          instance.searchBox = true;
          instance.isEditing = true;
          let mapCenter = {
            lat: data?.latitude?.value,
            lng: data?.longitude?.value
          }
          this.mapCenter = mapCenter
          // instance.locationDetails = this.mapCenter;
          instance.locationDetails = mapCenter;
          instance.mapInputId = googleSearchId;
          if (instance.emitLocation) {
            instance.emitLocation.subscribe((eventData: any) => {
              // Object.assign(this.chatData, { ...eventData.details, latitude: eventData?.lat, longitude: eventData?.lng });
              // this.sharedImagesServices.updateImages(eventData.images);
              // this.messages.push({ sender: 'user', message: `${eventData.details.address}`, action: 'query', data: this.chatData });
              // this.userInput = eventData.details.address
              // this.sendMessage()
              this.handleSendMessage(eventData.details.address)
              this.handleSuggestions(['Yes', 'Confirm', 'Change Location'], true);
              this.cdRef.detectChanges();
            })
          }
        }
        else if (componentName === 'GalleryComponent') {
          instance.isEditing = true;
          if (instance.uploadFiles) {
            instance.uploadFiles.subscribe((eventData: any) => {
              Object.assign(this.chatData, { images: eventData.filesToUpload });
            })
          }
        }
        else if (componentName === 'DynamicFormComponent') {
          instance.formData = data;
          let formData = {}

          if (instance.submitData) {
            instance.submitData.subscribe((eventData: any) => {
              let formattedMessage = '<h4 class="mt-0"><strong>Here are the details</strong></h4>';
              for (const [key, value] of Object.entries(eventData.formData)) {
                if (key !== 'clientId' && value) {
                  formattedMessage += `<div><strong>${key}:</strong> ${value}</div>`;
                  formData[key] = value;
                }
                else if (key == 'clientId' && eventData.data) {
                  formattedMessage += `<div><strong>Client:</strong> ${eventData.data.name}</div>`
                  formData["clientId"] = eventData.data.id;
                  formData["client"] = eventData.data.id;
                }
                else if (key == 'latitude' || key == 'longitude') {
                  formData['latitude'] = this.mapCenter['lat']
                  formData['longitude'] = this.mapCenter['lng']
                }
              }

              this.userInput = formattedMessage

              const lastMessage = this.messages.at(-1) || null;
              this.messages.push({ message: this.userInput, sender: 'user', action: 'query' });
              this.userInput = this.userInput + `<div><strong>ClientId:</strong> ${eventData.formData.client}</div>`
              this.sendMessageToAgent({ message: this.userInput, workflowName: lastMessage?.workflowName || "None", formData });
            })
          }
        }
      } else {
        console.error("No dynamic host container found.");
      }
    } else {
      console.error(`Component ${componentName} not found in the component map.`);
    }
  }

  ngOnDestroy() {
    this.agentsService.disconnectWebSocket();
  }
}
