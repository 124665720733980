import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {

  dynamicForm!: FormGroup;
  @Input() formData: any;
  @Output() submitData = new EventEmitter<any>();
  clientData: any = null
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (this.formData)
      this.createForm();
  }

  createForm() {
    const formControls: { [key: string]: FormControl } = {};

    Object.entries(this.formData).forEach(([key, value]) => {
      formControls[key] = new FormControl(value['value']);
    });

    this.dynamicForm = this.fb.group(formControls);
  }

  onCustomerSelect(event, key){
    this.clientData = event.data
    this.dynamicForm.patchValue({
      "client": event.data.id   
    })
  }

  onSubmit() {
    this.submitData.emit({formData: this.dynamicForm.value, data: this.clientData})
  }
}
