
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportWritingService {
  reportData = new BehaviorSubject<any>(null);
  reportData$ = this.reportData.asObservable();

  constructor() {}

  updateReport(data: any) {
    this.reportData.next(data);
  }
}
