import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { debounceTime } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CustomerService } from '../../customer/services/customer.service';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements AfterViewInit {
  
  @Input()
  customer: {
    name: '',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    photoLink: '',
    email: '',
    id: null
  };

  @Input() 
  customerList: any[];

  @Output() 
  onCustomerSelect = new EventEmitter<Object>();

  openClientSidebar: boolean = false;

  @ViewChild('input', { static: false })
  searchText;
  
  @ViewChild('myInput') 
  searchTextInput: ElementRef;

  @Input() showAddButton = true

  search: any;

  filteredList: any[];

  showlist: boolean = false;
  showData: boolean = true;

  // Get element referance
  @ViewChild('tgArea') tgArea: ElementRef;

  constructor(
    private customerService: CustomerService) { }

  ngAfterViewInit() {
    // Load default customers
    this.loadCustomers(null);
    // Bind value change on search text box
    this.searchText.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((model) => this.loadCustomers(model));
  }

  loadCustomers(text){
      this.customerService.getCustomers({ searchText : text, pageSize: (text && text.trim().length > 0) 
        && null, offset: 0, sortBy: 'firstName', sortOrder: 0 }).subscribe((res) => {
        if (res.data && res.data.customers) {
          this.customerList = res.data.customers.customers;
          // Sort data
          if(this.customerList){
            this.customerList.sort(function(st1, st2){
              if(st1.name > st2.name) return 1;
              if(st1.name < st2.name) return -1;
              return 0;
            });
            this.filteredList = this.customerList;
          }
          // Set name for each
          this.customerList.forEach((element) => {
            element.name = '';
            if (element.firstName != null) element.name += element.firstName;
            if (element.middleName != null) element.name += ' ' + element.middleName;
            if (element.lastName != null) element.name += ' ' + element.lastName;
          });
          //this.cdr.detectChanges();
        }
      });
  }

  handleSelectedStaff(selectedCustomer) {
    this.customer = selectedCustomer;
    this.onCustomerSelect.emit({ data: this.customer });
    this.showlist = false;
  }

  // Defind listener to check clickable area
  @HostListener("document:click" , ['$event'])
  clickedOut(event) {
    if(event.target == this.tgArea.nativeElement){
      this.showlist = true;
    }else{
      this.showlist = false;
    }
  }

  // Basic function to toggel list
  showHideList(){
    this.showlist =  !this.showlist;
  }

  // Function to open list and prevent other events
  showCustomerListWithEvent(e) {
    this.showlist = !this.showlist;
    e.preventDefault();
    e.stopPropagation();
  }
  
  customerDisplayName(){
    if(this.customer){
      this.customer.name = '';
        if(this.customer.firstName){
        if (this.customer.firstName != null) this.customer.name += this.customer.firstName;
        if (this.customer.middleName != null) this.customer.name += ' ' + this.customer.middleName;
        if (this.customer.lastName != null) this.customer.name += ' ' + this.customer.lastName;
        return this.customer.name;
      } else if(this.customer.email) {
        return this.customer.email;
      }
    }
  }

  createCustomer(event: any) {
    this.toggleSidebar(false);
    // emit event to populate the current client.
    this.filteredList.push(event);
    this.handleSelectedStaff(event);
  }

  toggleSidebar(isOpen: boolean) {
    this.openClientSidebar = isOpen;
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}
