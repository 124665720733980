import { Component } from '@angular/core';
import { propertyDummyData } from '../../property.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent {
  showMap: boolean = false;
  totalItems: number;
  filterPropertyData: {
    name: string;
    field: string;
    index: number;
    type: string;
    filter: boolean;
    width: number;
    options: Array<string>;
  } = {
      name: 'Property Type',
      field: 'property_type',
      index: 1,
      type: 'select',
      filter: false,
      width: 200,
      options: [
        'House',
        'Apartment',
        'Commercial',
        'Land'
      ],
    };

  propertyData: any;
  locationCoordinates: any[] = [];

  dtFilters: any = {
    projectType: "PROPERTY_LISTING",
    pageIndex: 1,
    pageSize: 10,
    offset: 0,
    pageSizes: [10, 20, 50, 100],
    sortBy: "property_id",
    sortOrder: 1,
    model: "",
    searchText: "",
    columnFilters: [],
  };

  dtColumns: any[] = []
  columns: any[] = [
    {
      name: "Property ID",
      field: "property_id",
      index: 1,
      type: "text",
      filter: true,
      clickable: true,
      width: 150,
      align: "left",
      headerAlign: "left",
      isEditable: true,
      visible:true
    },
    {
      name: 'Address',
      field: 'address',
      index: 2,
      type: 'custom',
      filter: false,
      customType: 'text',
      isChild: true,
      fields: ["street_name", "city", "state"],
      childName: 'address',
      model: 'address',
      isEditable: false,
      visible:true
    },
    {
      name: "Property Type",
      field: "property_type",
      index: 3,
      type: "select",
      filter: true,
      width: 180,
      options: ["Residential", "Commercial", "Industrial", "Agricultural"],
      headerAlign: "center",
      align: "center",
      isEditable: true,
      visible:true

    },
    {
      name: "Lot ID",
      field: "lot_id",
      index: 4,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },

    {
      name: 'Building',
      field: 'building',
      index: 2,
      type: 'custom',
      filter: false,
      customType: 'text',
      isChild: true,
      childName: 'building',
      model: 'building',
      isEditable: false,
      visible:true

    },
    {
      name: "Zoning",
      field: "zoning",
      index: 6,
      type: "text",
      filter: true,
      isEditable: true,
      visible:true

    },
    {
      name: "Parcel Number",
      field: "parcel_number",
      index: 7,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "APN",
      field: "apn",
      index: 8,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "Subdivision",
      field: "subdivision",
      index: 9,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "HOA Fees",
      field: "hoa_fees",
      index: 10,
      type: "number",
      filter: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      isEditable: true,
      visible:true

    },
    {
      name: "Water Source",
      field: "water_source",
      index: 11,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "Sewer System",
      field: "sewer_system",
      index: 12,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "Utilities",
      field: "utilities",
      index: 13,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "View",
      field: "view",
      index: 14,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "Flood Zone",
      field: "flood_zone",
      index: 15,
      type: "text",
      filter: false,
      isEditable: true,
      visible:true

    },
    {
      name: "Created At",
      field: "created_at",
      index: 16,
      type: "date",
      filter: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      isEditable: true,
      visible:true

    },
    {
      name: "Updated At",
      field: "updated_at",
      index: 17,
      type: "date",
      filter: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      visible:true
    },
    {
      name: "Owner ID",
      field: "owner_id",
      index: 18,
      type: "text",
      filter: false,
      visible:true
    },
    {
      name: "Listing ID",
      field: "listing_id",
      index: 19,
      type: "text",
      filter: false,
      visible:true
    }
  ];

  sortOptions = [
    { label: 'property id - Low to High', field: 'property_id', order: 1 },
    { label: 'property id - High to Low', field: 'property_id', order: -1 },
  ];
  
  selectedSort = this.sortOptions[0]; 
  
  onSortChange(selectedSort: any) {
    this.selectedSort = selectedSort;
  }
  
  constructor(public router: Router) {

  }

  ngOnInit() {
    this.dtColumns = [...this.columns];
    this.initializeTableData();
  }

  onColumnFilterChange(event: any) {
  }


  initializeTableData() {
    this.propertyData = { items: propertyDummyData, totalItems: propertyDummyData.length };
  }

  // Handle Filter Change Event
  onFilterChange(event: any) {
    // Apply filtering logic here
  }

  // Handle Cell Render
  onCellRender(cell: any) {
    switch (cell.field) {
      case 'property_id':
        return `<a href="/properties/${cell.data.property_id}" class="common-hyperlink-remove-style">${cell.data.property_id}</a>`;
      case 'address':
        {
          const address = cell?.data?.address;
          if (address) {
            const formattedAddress = `${address.street_number}, ${address.street_name}, ${address.city}, ${address.state}, ${address.zip_code}`;
            return formattedAddress.length > 50 ? formattedAddress.substring(0, 50) + "..." : formattedAddress;
          }
          return 'NA';
        }

      case 'building':
        {
          const building = cell?.data?.building;
          if (building) {
            const formattedBuilding = `${building.building_size} sqft, ${building.bedrooms} Beds, ${building.bathrooms} Baths, ${building.stories} Stories, Built in ${building.year_built}`;
            return formattedBuilding.length > 50 ? formattedBuilding.substring(0, 50) + "..." : formattedBuilding;
          }
          return 'NA';
        }

      default:
        return 'NA';
    }
  }

  cellClick(event: any) {
    event.coreEvent.preventDefault();
    this.viewProjectData(event.property_id);
  }


  // Handle Map Marker Click Event
  viewProjectData(id: number) {
    this.router.navigate([`/properties/${id}`]);
  }


  filterFields = this.columns.filter(col => col.filter); // Extract only filterable fields
  filterValues: any = {};

  handleFilterChange(event: { field: any, value: any }) {
    this.filterValues[event.field.field] = event.value;
    this.applyFilters();
  }

  saveFilters() {
    // Implement save logic (e.g., storing filters in localStorage)
  }

  resetFilters() {
    this.filterValues = {};
    this.applyFilters();
  }

  applyFilters() {
    // Implement logic to filter your property list based on `filterValues`
  }

  

}
