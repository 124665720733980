import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  private apiUrl = ``

  constructor(private http: HttpClient) { }

  downloadFile(data): any{
    return this.http.post(`${this.apiUrl}/api/download/report`, data, { responseType: "blob" });
  }
}
