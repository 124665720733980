<div *ngFor="let table of tables;let i = index">
    <div class="property-details-container" [ngClass]="{ 'mt-3': i > 0 }">
        <label *ngIf="table.title">{{ table.title }}</label>
        <div class="property-details-table">
            <app-custom-datatable 
                [columns]="table.columns" 
                [data]="table.data"
                [isVerticalTable]="true" 
                [columnsPerRow]="2" 
                [gridView]="true" 
                [editableGrid]="true"
                [isEditMode]="isEditMode"
                [onCellRender]="onCellRender"
                (onDataSave)="saveTableData($event, table.title)">
            </app-custom-datatable>
        </div>
    </div>
</div>
