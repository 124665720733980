<div class="user-profiler row">
    <div class="w-auto p-0">
        <img [src]="customer?.photoLink || '/assets/images/user.svg'" class="profiller-image" />
    </div>
    <div class="profiler-content w-auto p-0">
        <h5>
            <b>{{ customerDisplayName() || 'Select client' }}</b>
        </h5>
        <ng-container>
            <p class="sml-txt">{{ customer?.title || '--' }}</p>
        </ng-container>

        <div class="list-view-btn">
            <span (click)="showCustomerListWithEvent($event)" class="list-show-btn"><i
                    class="fas fa-chevron-down"></i></span>
        </div>
    </div>
    <div class="list-view-cnt" [ngClass]="showlist ? 'show' : ''">
        <div class="list-input">
            <input type="text" #tgArea placeholder="Start typing the name.." class="w-100" [(ngModel)]="search"
                #input="ngModel" id="search" name="search">

            <div class="flex justify-content-center" *ngIf="showAddButton">
                <button type="button" class="btn btn-primary w-100" (click)="toggleSidebar(true)">
                    Add New
                </button>
            </div>
            <section class="ta-results list-group" *ngFor="let cust of filteredList">
                <button type="button" class="ta-item list-group-item" (click)="handleSelectedStaff(cust)">
                    <div class="item-name">
                        <img [src]="cust.photoLink || '/assets/images/user.svg'" class="profiller-image" />
                        <div class="profiler-content">
                            <h5>
                                <b>{{cust.name || '--'}}</b>
                            </h5>
                            <small class="sml-txt">{{ cust.title || '--' }}</small>
                            <h6 class="mt-0">{{ cust.customerCompany?.companyName || '--' }}</h6>
                        </div>
                    </div>
                </button>
            </section>
        </div>
    </div>
</div>

<!-- Slider Wrapper -->
<div class="slider-wrapper" *ngIf="openClientSidebar">
    <!-- Backdrop -->
    <div class="backdrop" (click)="toggleSidebar(false)"></div>
    <!-- Slider Container -->
    <div class="slider-container">
        <app-add-client (closeSideBox)="toggleSidebar(false)" (createClient)="createCustomer($event)"></app-add-client>
    </div>
</div>